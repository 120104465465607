const firebase = require('firebase/app');
require('firebase/messaging');
const config = {
    apiKey: "AIzaSyAmzmrNXSdg38D-nko32XyYosJd4k_Tn8A",
    authDomain: "neopwaindosat.firebaseapp.com",
    projectId: "neopwaindosat",
    storageBucket: "neopwaindosat.appspot.com",
    messagingSenderId: "755888264391",
    appId: "1:755888264391:web:67c8b1feadc9922f65dae2"
}
firebase.initializeApp(config)
const messaging = firebase.messaging();
export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });
export default firebase