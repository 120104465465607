import React, { Component } from "react";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { namaHeader: "" };
  }

  getUrl() {
    var getUrl = window.location.href.split("/");
    // var split = getUrl.split("/");
    // console.log(getUrl);
    if (getUrl.length == 4) {
      let str = getUrl[3];
      let outStr = str.replace(/[^\w\s]/g, "");

      this.setState({
        namaHeader:
          getUrl[3] != "EditProfile"
            ? outStr == "dashboard"
              ? "HOME"
              : outStr.toUpperCase()
            : "PROFILE",
      });
    } else if (getUrl.length > 4) {
      let str = getUrl[4];
      let outStr = str.replace(/[^\w\s]/g, "");

      if (getUrl[4].includes("viewexplore")) {
        this.setState({
          namaHeader: "Explore",
        });
      } else if (getUrl[3].includes("dashboard#")) {
        this.setState({ namaHeader: "HOME" });
      } else if (getUrl[3].includes("Cutomize")) {
        this.setState({ namaHeader: "My Profile" });
      } else if (getUrl[3].includes("Profile")) {
        this.setState({ namaHeader: "Profile" });
      } else {
        this.setState({
          namaHeader:
            outStr.toUpperCase() == "APP"
              ? "MY APPS"
              : getUrl[4].includes("stage") ||
                getUrl[4].includes("mission") ||
                getUrl[4].includes("checkpoint")
              ? "NEO RACE"
              : outStr.toUpperCase(),
        });
      }
    }
  }
  componentDidMount() {
    this.getUrl();
  }

  render() {
    return (
      <div className="header header-fixed header-auto-show header-logo-app">
        <a
          href={"#/"}
          data-back-button
          className="header-title header-subtitle"
        >
          {this.state.namaHeader}
        </a>

        <a
          href={"#/"}
          data-toggle-theme
          className="header-icon header-icon-2 show-on-theme-dark"
        >
          <i className="fas fa-sun" />
        </a>
        <a
          href={"#/"}
          data-toggle-theme
          className="header-icon header-icon-2 show-on-theme-light"
        >
          <i className="fas fa-moon" />
        </a>
        {/*
            <a href={"#/"} data-menu="menu-highlights" class="header-icon header-icon-3"><i class="fas fa-brush"></i></a>
        */}
        <a
          href={"#"}
          data-menu="menu-main"
          className="header-icon header-icon-3"
        >
          <i className="fas fa-bars" />
        </a>
      </div>
    );
  }
}

export default Header;
