import React, { Component } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { geolocated } from "react-geolocated";
class MapCard extends Component {
  constructor(props) {
    super(props);
    this.state = { latitude: 0, longitude: 0, zoom: 5 };
    this.getMyLocation = this.getMyLocation.bind(this);
  }
  getMyLocation() {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          this.setState({
            latitude: "err-latitude",
            longitude: "err-longitude",
          });
        }
      );
    }
  }
  GetIcon(_iconSize) {
    return L.icon({
      iconUrl: require("../../StaticIcons/unnamed.png"),
      iconSize: [_iconSize],
    });
  }

  position = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) =>
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }),
      (err) => console.log(err)
    );
    console.log(this.state.latitude);
  };
  componentDidMount() {
    console.log(this.props);
    this.position();
  }
  render() {
    const position = [this.state.latitude, this.state.longitude];
    return (
      <div>
        <label>
          {this.props.props.no + "."}
          {this.props.props.dataJSON.name}
        </label>
        {this.props.props.dataJSON.note}
        <div className="card card-style">
          <div
            className="responsive-iframe add-iframe"
            style={{ height: "300px" }}
          >
            <Map
              center={position}
              zoom={this.state.zoom}
              style={{ height: "300px", width: "100%" }}
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={position} icon={this.GetIcon(30)}>
                <Popup style={{ backgroundColor: "red" }}>My location</Popup>
              </Marker>
            </Map>
          </div>
        </div>
        <div className="content">
          <div
            onClick={() => {
              this.getMyLocation();
              this.props.props.onChange({
                id: this.props.props.dataJSON.id,
                answer: `${this.state.latitude},${this.state.longitude}`,
              });
            }}
            className="btn btn-full btn-m bg-red2-dark rounded-xl text-uppercase shadow-l font-900"
          >
            Get my Location
          </div>
        </div>
      </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(MapCard);
