import React, { Component } from "react";

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className="card card-style">
          <div className="content mb-2">
            <h3>FAQs</h3>
            {/* <p className="color-highlight font-12 mt-n2 mb-2">Really, we get asked this often.</p> */}
            <p>
              {/* We get asked these questions a lot, so we made this small section to help you out identifying what you need faster. */}
            </p>
            <div className="divider mt-2 mb-2" />
            <div className="d-flex">
              <div className="pt-1">
                <h5 data-activate="question-1" className="font-600">
                  {" "}
                  Apa itu NEO Program ?
                </h5>
              </div>
              <div className="ml-auto mr-1 pr-2">
                <div className="custom-control classic-switch">
                  <input
                    type="checkbox"
                    className="classic-input"
                    id="question-1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="question-1"
                  />
                  <i className="fa fa-angle-down font-11 color-red2-dark" />
                </div>
              </div>
            </div>
            <div data-switch="question-1" className="switch-is-unchecked">
              <p className="pb-3">
                NEO (New Employee Orientation) Program ada suatu program untuk
                membantu sahabat agar lebih cepat memahami kondisi dan situasi
                kerja yang akan dihadapi
              </p>
            </div>
            <div className="divider mt-2 mb-2" />
            <div className="d-flex">
              <div className="pt-1">
                <h5 data-activate="question-2" className="font-600">
                  Apa saja yang akan dipelajari di NEO Program ?
                </h5>
              </div>
              <div className="ml-auto mr-1 pr-2">
                <div className="custom-control classic-switch">
                  <input
                    type="checkbox"
                    className="classic-input"
                    id="question-2"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="question-2"
                  />
                  <i className="fa fa-angle-down font-11 color-red2-dark" />
                </div>
              </div>
            </div>
            <div data-switch="question-2" className="switch-is-unchecked">
              <p className="pb-3">
                Neo berisi sejarah tentang indosat, Bisnis inti , Budaya
                Perusahaan sampai Pengenalan Peraturan Kerja yang berlaku secara
                umum di perusahaan
              </p>
            </div>
            <div className="divider mt-2 mb-2" />
            <div className="d-flex">
              <div className="pt-1">
                <h5 data-activate="question-3" className="font-600">
                  Apa itu Buddy ?
                </h5>
              </div>
              <div className="ml-auto mr-1 pr-2">
                <div className="custom-control classic-switch">
                  <input
                    type="checkbox"
                    className="classic-input"
                    id="question-3"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="question-3"
                  />
                  <i className="fa fa-angle-down font-11 color-red2-dark" />
                </div>
              </div>
            </div>
            <div data-switch="question-3" className="switch-is-unchecked">
              <p className="pb-3">
                Buddy adalah pendamping yang telah di tunjuk oleh line manager
                untuk setiap karyawan baru selama 3 bulan masa adaptasi. Buddy
                memilik tugas utama untuk memastikan setiap item
                persiapan(Sesuai dengan yang telah dicantumkan di orientation
                guidance) telah diperbaharui.
              </p>
            </div>
            <div className="divider mt-2 mb-2" />
            <div className="d-flex">
              <div className="pt-1">
                <h5 data-activate="question-4" className="font-600">
                  Apakah wajib bagi karyawan baru menyelesaikan NEO Online
                  Learning ?
                </h5>
              </div>
              <div className="ml-auto mr-1 pr-2">
                <div className="custom-control classic-switch">
                  <input
                    type="checkbox"
                    className="classic-input"
                    id="question-4"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="question-4"
                  />
                  <i className="fa fa-angle-down font-11 color-red2-dark" />
                </div>
              </div>
            </div>
            <div data-switch="question-4" className="switch-is-unchecked">
              <p>
                Wajib diselesaikan dalaman dua minggu terhitung tanggal masuk
                perusahaan (TMT) sampai mendapatkan sertifikat oniline.
              </p>
            </div>
            <div className="divider mt-2 mb-2" />
            <div className="d-flex">
              <div className="pt-1">
                <h5 data-activate="question-5" className="font-600">
                  Bagaimana saya bisa mengakses NEO Program ?
                </h5>
              </div>
              <div className="ml-auto mr-1 pr-2">
                <div className="custom-control classic-switch">
                  <input
                    type="checkbox"
                    className="classic-input"
                    id="question-5"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="question-5"
                  />
                  <i className="fa fa-angle-down font-11 color-red2-dark" />
                </div>
              </div>
            </div>
            <div data-switch="question-5" className="switch-is-unchecked">
              <p className="pb-3">
                Web Program dapat diakses melalui Aplikasi dan Web Mylearning
              </p>
            </div>
          </div>
         
        </div>
        
      </div>
      //   <div className="card card-style">
      //     <div className="content mb-0">
      //       <h3 className="font-700">FAQs</h3>
      //       <p>General</p>
      //     </div>
      //     <div className="accordion mt-4" id="accordion-6">
      //       <div className="card card-style shadow-0 bg-red2-dark mb-0">
      //         <button
      //           className="btn accordion-btn border-0  color-white"
      //           data-toggle="collapse"
      //           data-target="#collapse1"
      //         >
      //           <i className="fa fa-info mr-2" />
      //           Apa itu NEO Program ?
      //           <i className="fa fa-chevron-down font-10 accordion-icon" />
      //         </button>
      //         <div
      //           id="collapse1"
      //           className="collapse bg-theme"
      //           data-parent="#accordion-6"
      //         >
      //           <div className="p-3 color-theme">
      //             NEO (New Employee Orientation) Program ada suatu program untuk
      //             membantu sahabat agar lebih cepat memahami kondisi dan situasi
      //             kerja yang akan dihadapi
      //           </div>
      //         </div>
      //       </div>
      //       <div className="card card-style shadow-0 bg-red2-dark mb-0">
      //         <button
      //           className="btn accordion-btn border-0  color-white"
      //           data-toggle="collapse"
      //           data-target="#collapse2"
      //         >
      //           <i className="fa fa-info mr-2" />
      //           Apa saja yang akan dipelajari di NEO Program ?
      //           <i className="fa fa-chevron-down font-10 accordion-icon" />
      //         </button>
      //         <div
      //           id="collapse2"
      //           className="collapse bg-theme"
      //           data-parent="#accordion-6"
      //         >
      //           <div className="p-3 color-theme">
      //             Neo berisi sejarah tentang indosat, Bisnis inti , Budaya
      //             Perusahaan sampai Pengenalan Peraturan Kerja yang berlaku secara
      //             umum di perusahaan
      //           </div>
      //         </div>
      //       </div>
      //       <div className="card card-style shadow-0 bg-red2-dark mb-0">
      //         <button
      //           className="btn accordion-btn border-0  color-white"
      //           data-toggle="collapse"
      //           data-target="#collapse3"
      //         >
      //           <i className="fa fa-info mr-2" />
      // Apa itu Buddy?
      //           <i className="fa fa-chevron-down font-10 accordion-icon" />
      //         </button>
      //         <div
      //           id="collapse3"
      //           className="collapse bg-theme"
      //           data-parent="#accordion-6"
      //         >
      //           <div className="p-3 color-theme">
      // Buddy adalah pendamping yang telah di tunjuk oleh line manager
      // untuk setiap karyawan baru selama 3 bulan masa adaptasi. Buddy
      // memilik tugas utama untuk memastikan setiap item
      // persiapan(Sesuai dengan yang telah dicantumkan di orientation
      // guidance) telah diperbaharui.
      //           </div>
      //         </div>
      //       </div>
      //       <div className="card card-style shadow-0 bg-red2-dark mb-0">
      //         <button
      //           className="btn accordion-btn border-0  color-white"
      //           data-toggle="collapse"
      //           data-target="#collapse4"
      //         >
      //           <i className="fa fa-info mr-2" />
      // Apakah wajib bagi karyawan baru menyelesaikan NEO Online Learning
      //           <i className="fa fa-chevron-down font-10 accordion-icon" />
      //         </button>
      //         <div
      //           id="collapse4"
      //           className="collapse bg-theme"
      //           data-parent="#accordion-6"
      //         >
      //           <div className="p-3 color-theme">
      // Wajib diselesaikan dalaman dua minggu terhitung tanggal masuk
      // perusahaan (TMT) sampai mendapatkan sertifikat oniline.
      //           </div>
      //         </div>
      //       </div>
      //       <div className="card card-style bg-red2-dark">
      //         <button
      //           className="btn accordion-btn border-0 color-white"
      //           data-toggle="collapse"
      //           data-target="#collapse13"
      //         >
      //           <i className="fa fa-info mr-2" />
      // Bagaimana saya bisa mengakses NEO Program ?
      //           <i className="fa fa-chevron-down font-10 accordion-icon" />
      //         </button>
      //         <div
      //           id="collapse13"
      //           className="collapse bg-theme"
      //           data-parent="#accordion-6"
      //         >
      //           <div className="p-3 color-theme">
      //             Web Program dapat diakses melalui Aplikasi dan Web Mylearning
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
    );
  }
}

export default Info;
