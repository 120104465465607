import React, { Component, useState } from "react";
import "../smartphone.css";
class SmartPhone extends Component {
  componentDidMount() {
    window.$("#root").css("height", "100vh");
  }
  render() {
    return (
      <div className="smartphone" style={{ marginTop: "20px" }} id="page">
        <div
          className="content"
          style={{ margin: "auto", border: "none", height: "100%" }}
        >
          {/* <iframe
            src="https://neo-pwa.web.app/login"
            style={{ width: "100%", border: "none", height: "100%" }}
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin"
          ></iframe> */}
        </div>
      </div>
    );
  }
}
export default SmartPhone;
