import React, { Component } from "react";
import BadgesItemAll from "../component/profile/BadgesItemAll";
import MyBadges from "../component/profile/MyBadges";
import MyBadgesViewAll from "../component/profile/MyBadgesViewAll";
import { getUser } from "../utils/Common";
import { getBadges, getMyBadges } from "../utils/Repository";

class ViewAllBadges extends Component {
  constructor(props) {
    super(props);
    this.state = { dataBadges: [], badge: [], myBadges: [] };
  }

  getApi() {
    return getBadges()
      .then((resp) => resp.data)
      .then((data) => this.setState({ dataBadges: data.result }))
      .catch((error) => console.log(error));
  }
  getMyBadges() {
    return getMyBadges(getUser()[0].nik)
      .then((resp) => resp.data)
      .then((data) => this.setState({ myBadges: data.result }))
      .catch((error) => console.log(error));
  }
  getDataBadgeComponent(data) {
    this.setState({ badge: data });
    // console.log(data)
  }

  componentDidMount() {
    this.getMyBadges();
    this.getApi();
  }
  render() {
    const listmyBadges = this.state.myBadges.map((data, index) => {
      return (
        <MyBadgesViewAll
          myBadges={data}
          key={index}
          getData={this.getDataBadgeComponent.bind(this)}
        />
      );
    });
    const listBadges = this.state.dataBadges.map((data, index) => {
      var checkData = this.state.myBadges.find(
        (element) => element.name == data.name
      );
      return (
        <BadgesItemAll
          dataBadges={data}
          key={index}
          myBadge={checkData ? "none" : "block"}
          getData={this.getDataBadgeComponent.bind(this)}
        />
      );
    });
    return (
      <div>
        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src={
              this.props.image != null
                ? this.props.image
                : "images/avatars/2s.png"
            }
          />
        </div>
        <div className="card card-style bg-theme pb-0">
          <div className="content">
            <div
              className="tab-controls tabs-round tab-animated tabs-medium tabs-rounded shadow-xl"
              data-tab-items={2}
              data-tab-active="bg-yellow1-dark color-white"
            >
              <a href="#" data-tab-active data-tab="tab-8">
                EARNED
              </a>
              <a href="#" data-tab="tab-9">
                AVAILABLE
              </a>
            </div>
            <div className="clearfix mb-3" />
            <div className="tab-content" id="tab-8">
              <div className="row px-1">{listmyBadges}</div>
            </div>
            <div className="tab-content" id="tab-9">
              <div className="row px-2">{listBadges}</div>
            </div>
          </div>
        </div>
        <div
          id="menu-success-1"
          className="menu menu-box-modal rounded-m"
          data-menu-width={310}
        >
          <div className="clearfix mb-n3" />
          <h1 className="text-center mt-5 font-700">{this.state.badge.name}</h1>
          <p className="boxed-text-l">{this.state.badge.description}</p>
          <div className="clearfix mb-n3" />
          <div className="pl-5">
            <a className="text-center pt-3 float-left">
              <img
                src={this.state.badge.imgurl}
                width={55}
                height={55}
                className="mx-2 rounded-xl owl-lazy shadow-l bg-gradient-dark1"
              />
              <p>Platinum</p>
            </a>
            <a className="text-center pt-3 float-left">
              <img
                src={this.state.badge.imgurl}
                width={55}
                height={55}
                className="mx-2 rounded-xl owl-lazy shadow-l bg-gradient-yellow1"
              />
              <p>Gold</p>
            </a>
            <a className="text-center pt-3 float-left">
              <img
                src={this.state.badge.imgurl}
                width={55}
                height={55}
                className="mx-2 rounded-xl owl-lazy shadow-l bg-gradient-gray2"
              />
              <p>Silver</p>
            </a>
          </div>
          <div className="clearfix mb-n3" />
          <a
            href="#"
            style={{ marginBottom: "20px" }}
            className="close-menu rounded-xl btn btn-m btn-center-m button-s shadow-l rounded-s text-uppercase font-900 bg-red1-dark"
          >
            Close
          </a>
        </div>
      </div>
      //   <div className="page-content">
      //
      //   </div>
    );
  }
}

export default ViewAllBadges;
