import React, { Component, useState } from "react";
import { setUserSession, setImage, setCeo, setRole, getTokenFcm, setToken, getToken } from "../utils/Common";
import axios from "axios";
import {
  getProfileUser,
  getProfileUserLogin,
  generateToken,
  getLogin,
} from "../utils/Repository";
import { isSafari } from "react-device-detect";
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isLoading: false,
      error: false,
      txterror: "",
    };
    this.login = this.login.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  componentDidMount() {
    if (!isSafari) {
      generateToken();
    }
  }

  login() {
    this.setState({ isLoading: true });
    let form = new FormData();
    let deviceid = getTokenFcm();
    form.append("username", this.state.username);
    form.append("password", this.state.password);
    form.append("deviceid", isSafari ? "Safari" : deviceid);
    return getLogin(form).then((response) => {
      if (response.status === 200) {
        this.setState({ isLoading: false });
        if (response.data.errorMessage != "Username atau Password Salah") {
          setUserSession(response.data.result.NIK);
          setRole(response.data.result.additional_information[0].user_status);
          setToken(response.data.token);
          // setImage(response.data.result.avatarImage);
          setCeo(response.data.result.activity.watch_opening);
          getProfileUserLogin(this.state.username);
          generateToken();
        } else {
          this.setState({
            error: true,
            txterror: "your username or password is wrong",
          });
          console.log(response.data.errorMessage);
        }
      }
    })
      .catch((err) => console.error(err));
  }

  render() {
    return (
      <div id="page">
        <div className="header header-fixed header-auto-show header-logo-app text-center">
          <a href="#" data-back-button className="header-title header-subtitle">
            New Employee Orientation
          </a>
        </div>
        <div className="page-content full">
          <div className="page-title page-title-small retain-title title-login">
            <h2 className="text-center">New Employee Orientation</h2>
          </div>
          <div className="card card-style login-card">
            <div className="content mt-2 mb-0 p-3">
              <div className="text-center">
                <img
                  src="/neo.png"
                  data-src="/neo.png"
                  className="preload-img img-fluid rounded-xs"
                  alt="img"
                  width="100px"
                  style={{ paddingBottom: "20px" }}
                />
              </div>
              <div className="input-style has-icon input-style-1 input-required pb-1">
                <i className="input-icon fa fa-user color-theme" />
                <span>Username</span>
                <input
                  type="text"
                  id="username"
                  onChange={this.handleOnChange}
                  placeholder="Username"
                  required
                />
              </div>
              <div className="input-style has-icon input-style-1 input-required pb-1">
                <i className="input-icon fa fa-lock color-theme" />
                <span>Password</span>
                <input
                  type="password"
                  id="password"
                  onChange={this.handleOnChange}
                  placeholder="Password"
                  required
                />
              </div>
              <center>
                <center>
                  <p
                    style={
                      this.state.error
                        ? { color: "red", display: "block" }
                        : { display: "none" }
                    }
                  >
                    {this.state.txterror}
                  </p>
                </center>
              </center>
              <button
                style={{ width: "100%", backgroundColor: "#ED1C24" }}
                className="btn btn-m mt-4 rounded-xl bg-indosat-merah-dark btn-full  rounded-sm text-uppercase font-900"
                type="button"
                onClick={this.login}
              >
                {this.state.isLoading ? "Loading..." : "Login"}
              </button>
            </div>
          </div>
          <div
            className="card header-card shape-rounded"
            data-card-height={150}
          >
            <div className="card-overlay bg-highlight opacity-95" />
            <div className="card-overlay dark-mode-tint" />
            <div
              className="card-bg preload-img"
              data-src="images/pictures/20s.jpg"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
