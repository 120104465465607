import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getUser, getRole } from "../../utils/Common";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const role = getRole();
    return (
      <div>
        <div className="card card-style">
          <div className="content mt-0 pt-2 mb-2">
            <h5>Welcome</h5>
            <p>
              Here we are in the classroom where you can learn and gain more
              knowledge and also interact with your classmate and Instructor.
            </p>
          </div>
        </div>
        <div className="classroom-links">
          <div>
            <Link to="/Classroom/explore" className="card card-style mb-3">
              <div
                className="card mb-0"
                data-card-height={160}
                style={{ height: "160px" }}
              >
                <img
                  src="/images/pictures/NEO explorer.png"
                  alt=""
                  style={{ height: "160px" }}
                />
                {/* <div className="card-overlay bg-gradient-fade rounded-0" /> */}
              </div>
              <div className="d-flex pt-2 pb-3">
                <div className="mt-2 pl-3 ml-2">
                  <h1 className="center-text">
                    <i
                      data-feather="send"
                      data-feather-line={1}
                      data-feather-size={50}
                      data-feather-color="yellow1-dark"
                      data-feather-bg="gray1-fade-light"
                    ></i>
                  </h1>
                </div>
                <div className="pt-2 mt-1 pl-4">
                  <h4 className="color-theme font-600"> Explore</h4>
                  <p className="mt-n2 font-11 color-highlight mb-2">
                    Watch and learn more about Indosat PT. Tbk.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <Link to="/Classroom/myclass" className="card card-style mb-3">
              <div
                className="card mb-0"
                data-card-height={160}
                style={{ height: "160px" }}
              >
                <img
                  src="/images/pictures/NEO myclass.png"
                  alt=""
                  style={{ height: "160px" }}
                />
                {/* <div className="card-overlay bg-gradient-fade rounded-0" /> */}
              </div>
              <div className="d-flex pt-2 pb-3">
                <div className="mt-2 pl-3 ml-2">
                  <h1 className="center-text">
                    <i
                      data-feather="star"
                      data-feather-line={1}
                      data-feather-size={50}
                      data-feather-color="yellow1-dark"
                      data-feather-bg="gray1-fade-light"
                    ></i>
                  </h1>
                </div>
                <div className="pt-2 mt-1 pl-4">
                  <h4 className="color-theme font-600"> MyClass</h4>
                  <p className="mt-n2 font-11 color-highlight mb-2">
                    Interact with your classmate and Instructor
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <Link to="/Classroom/info" className="card card-style mb-3">
              <div
                className="card bg-13 mb-0"
                data-card-height={160}
                style={{ height: "160px" }}
              >
                <img
                  src="/images/pictures/NEO information.png"
                  alt=""
                  style={{ height: "160px" }}
                />
                {/* <div className="card-overlay bg-gradient-fade rounded-0" /> */}
              </div>
              <div className="d-flex pt-2 pb-3">
                <div className="mt-2 px-3 ml-2">
                  <h1 className="center-text">
                    <i className="fa fa-question color-yellow1-dark font-36 ml-2" />
                  </h1>
                </div>
                <div className="pt-2 mt-1 pl-4">
                  <h4 className="color-theme font-600"> Information Center</h4>
                  <p className="mt-n2 font-11 color-highlight mb-2">
                    FAQ with it's answer.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <Link
              // to={
              //   role == "admin"
              //     ? `/chatbatch`
              //     : `/Chatroom/${getUser()[0].batch_id}`
              // }
              to="#"
              className="card card-style mb-3"
            >
              <div
                className="card bg-13 mb-0"
                data-card-height={160}
                style={{ height: "160px" }}
              >
                <img
                  src="/images/pictures/NEO chatroom.png"
                  alt=""
                  style={{ height: "160px" }}
                />
                <div className="card-overlay bg-gradient-fade rounded-0" />
              </div>
              <div className="d-flex pt-2 pb-3">
                <div className="mt-2 px-3 ml-2">
                  <h1 className="center-text">
                    <i className="fa fa-comments color-yellow1-dark font-36 ml-2" />
                  </h1>
                </div>
                <div className="pt-2 mt-1 pl-4">
                  <h4 className="color-theme font-600"> Chatroom</h4>
                  <p className="mt-n2 font-11 color-highlight mb-2">
                    Live chat with classmates and instructors
                  </p>
                </div>
              </div>

              <div className="card-overlay bg-black opacity-80">
                <h2
                  className="text-center"
                  style={{ color: "white", marginTop: "100px" }}
                >
                  Coming soon
                </h2>
              </div>
            </Link>
          </div>
        </div>

        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src="images/pictures/20s.jpg"
          />
        </div>
      </div>
    );
  }
}

export default Index;
