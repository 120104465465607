import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class CardStage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClick(raceid, stage, sizeStage) {
    window.location.href = `/Neorace/stage?raceId=${raceid}&stage=${stage}&cStage=${sizeStage}`;
  }

  onClickAlert() {
    window.$("#toast-4").toast("show");
  }

  componentDidMount() {
    console.log(this.props);
    setTimeout(this.currentItem, 2500, this.props);
  }

  currentItem(props) {
    if (
      props.dataCompletion[props.no] &&
      props.dataJSON.is_complete == "FALSE"
    ) {
      window.$("#sliderr").trigger("to.owl.carousel", [props.no + 1, 1]);
    }
  }

  render() {
    return (
      <div
        data-card-height="cover-card"
        className="card card-style race-item"
        style={{ height: window.innerHeight / 1.3 + "px" }}
        onClick={() => {
          this.props.dataJSON.is_complete == "FALSE" &&
          this.props.dataJSON.stage == "1"
            ? this.onClick(
                this.props.dataJSON.id,
                this.props.dataJSON.stage,
                this.props.size
              )
            : this.props.dataCompletion[this.props.no]
            ? this.onClick(
                this.props.dataJSON.id,
                this.props.dataJSON.stage,
                this.props.size
              )
            : this.onClickAlert();
        }}
      >
        <img
          className="card-img-top"
          style={{ height: "100%" }}
          src={
            this.props.dataJSON.stage == 1
              ? "../images/neorace/1.jpg"
              : this.props.dataJSON.stage == 2
              ? "../images/neorace/2.jpg"
              : this.props.dataJSON.stage == 3
              ? "../images/neorace/3.jpg"
              : this.props.dataJSON.stage == 4
              ? "../images/neorace/4.jpg"
              : this.props.dataJSON.stage == 5
              ? "../images/neorace/5.jpg"
              : "../images/neorace/6.jpg"
          }
          alt="Card image cap"
        />
        <div className="card-center text-center">
          <h1 className="color-white bolder fa-4x mb-4">
            STAGE {this.props.dataJSON.stage}
          </h1>
          <p className="color-white fa-2x opacity-90 mb-5">
            <small>
              Total Missions : {this.props.dataJSON.jmlQuest}
              <br />
              Status :{" "}
              {this.props.dataJSON.is_complete == "FALSE" &&
              this.props.dataJSON.stage == "1"
                ? this.props.dataJSON.jmlCompletion +
                  " / " +
                  this.props.dataJSON.jmlQuest
                : this.props.dataCompletion[this.props.no] &&
                  this.props.dataJSON.is_complete == "TRUE"
                ? "Completed "
                : this.props.dataCompletion[this.props.no] &&
                  this.props.dataJSON.is_complete == "FALSE"
                ? this.props.dataJSON.jmlCompletion +
                  " / " +
                  this.props.dataJSON.jmlQuest
                : "Locked"}{" "}
              {this.props.dataJSON.is_complete == "FALSE" &&
              this.props.dataJSON.stage == "1" ? (
                ""
              ) : this.props.dataCompletion[this.props.no] &&
                this.props.dataJSON.is_complete == "TRUE" ? (
                <i className="fa fa-check color-green1-dark" />
              ) : this.props.dataCompletion[this.props.no] &&
                this.props.dataJSON.is_complete == "FALSE" ? (
                ""
              ) : (
                <i className="fa fa-lock color-red1-dark"></i>
              )}{" "}
            </small>
          </p>

          {this.props.dataJSON.is_complete == "FALSE" &&
          this.props.dataJSON.stage == "1" ? (
            <div
              onClick={() => {
                this.props.dataJSON.is_complete == "FALSE" &&
                this.props.dataJSON.stage == "1"
                  ? this.onClick(
                      this.props.dataJSON.id,
                      this.props.dataJSON.stage,
                      this.props.size
                    )
                  : this.props.dataCompletion[this.props.no]
                  ? this.onClick(
                      this.props.dataJSON.id,
                      this.props.dataJSON.stage,
                      this.props.size
                    )
                  : this.onClickAlert();
              }}
              className=" btn btn-m bg-highlight rounded-xl shadow-l text-uppercase font-900"
            >
              Let's Continue
            </div>
          ) : this.props.dataCompletion[this.props.no] &&
            this.props.dataJSON.is_complete == "TRUE" ? (
            <div
              onClick={() => {
                this.props.dataJSON.is_complete == "FALSE" &&
                this.props.dataJSON.stage == "1"
                  ? this.onClick(
                      this.props.dataJSON.id,
                      this.props.dataJSON.stage,
                      this.props.size
                    )
                  : this.props.dataCompletion[this.props.no]
                  ? this.onClick(
                      this.props.dataJSON.id,
                      this.props.dataJSON.stage,
                      this.props.size
                    )
                  : this.onClickAlert();
              }}
              className=" btn btn-m bg-highlight rounded-xl shadow-l text-uppercase font-900"
            >
              Check Stage Missions
            </div>
          ) : this.props.dataCompletion[this.props.no] &&
            this.props.dataJSON.is_complete == "FALSE" ? (
            <div
              href="neo-mission.html"
              className=" btn btn-m bg-highlight rounded-xl shadow-l text-uppercase font-900"
              onClick={() => {
                this.props.dataJSON.is_complete == "FALSE" &&
                this.props.dataJSON.stage == "1"
                  ? this.onClick(
                      this.props.dataJSON.id,
                      this.props.dataJSON.stage,
                      this.props.size
                    )
                  : this.props.dataCompletion[this.props.no]
                  ? this.onClick(
                      this.props.dataJSON.id,
                      this.props.dataJSON.stage,
                      this.props.size
                    )
                  : this.onClickAlert();
              }}
            >
              Let's Continue
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="card-overlay bg-black opacity-80" />
      </div>
    );
  }
}

export default withRouter(CardStage);
