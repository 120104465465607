import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import LoginPage from "./page/LoginPage";
import Profile from "./page/Profile";
import Header from "./component/Header";
import NavBarBot from "./component/NavBottom";
import Classroom from "./page/Classroom";
import NeoRace from "./page/NeoRace";
import Customize from "./page/Customize";
import Sidebar from "./component/Sidebar";
import TopBar from "./component/TopBar";
import EditProfile from "./page/EditProfile";
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRourte";
import PlaylistVideo from "./page/PlaylistVideo";
import History from "./page/History";
import { getToken } from "./utils/Common";
import Chatlist from "./page/Chatlist";
import Chatroom from "./page/Chatroom";
import ViewAllBadges from "./page/ViewAllBadges";
import ImportantContact from "./page/ImportantContact";
import Apps from "./page/Apps";
import './smartphone.css';
import SmartPhone from "./page/smartphone";
import SideMenu from "./component/SideMenu";
import AnotherProfile from "./page/AnotherProfile";

import { getUser } from "./utils/Common";
import { getAuth, getProfil } from "./utils/Repository";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: "",
      loading: true
    };
  }
  renderLoader = () => (
    <div id="preloader">
      <div class="spinner-border color-highlight" role="status"></div>
    </div>
  );



  componentDidMount() {
    demoAsyncCall().then(() => this.setState({ loading: false }));
    getAuth();
  }


  renderHeader = () => {
    if (getToken() != null) {
      return (
        <Header />
      )
    }
  }
  renderNavBot = () => {
    if (getToken() != null) {
      return (
        <NavBarBot />
      )
    }
  }
  renderTopBar = () => {
    if (getToken() != null) {
      return (
        <TopBar />
      )
    }
  }
  renderSidebar = () => {
    if (getToken() != null) {

      return (
        <Sidebar />
      )
    }
  }
  renderSideMenu = () => {
    if (getToken() != null) {
      return (
        <SideMenu />
      )
    }
  }


  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PublicRoute path="/" exact component={LoginPage} />
          <PublicRoute path="/desktop" exact component={SmartPhone} />
          <PublicRoute path="/login" exact component={LoginPage} />

          <PrivateRoute path="/playvideo" exact component={PlaylistVideo} />
          <PrivateRoute path="/chatroom/:batchid" component={Chatroom} />
          <React.Fragment>
            <div id="page">
              {this.renderHeader()}
              {this.renderNavBot()}
              <div className={`page-content`}>
                {this.renderTopBar()}
                <PrivateRoute path="/dashboard" exact component={Profile} />
                <PrivateRoute path="/Classroom" component={Classroom} />
                <PrivateRoute path="/Neorace" component={NeoRace} />
                <PrivateRoute path="/Customize" component={Customize} />
                <PrivateRoute path="/EditProfile" component={EditProfile} />
                <PrivateRoute path="/History" component={History} />
                <PrivateRoute path="/chatbatch" component={Chatlist} />
                <PrivateRoute path="/AllBadges" component={ViewAllBadges} />
                <PrivateRoute path="/Contact" component={ImportantContact} />
                <PrivateRoute path="/Apps" component={Apps} />
                <PrivateRoute path="/Profile/:nik" component={AnotherProfile} />
              </div>
              {this.renderSideMenu()}
              {this.renderSidebar()}
            </div>
            <div id="preloader">
              <div className="spinner-border color-highlight" role="status"></div>
            </div>
          </React.Fragment>
        </Switch>
      </BrowserRouter>
    );

  }

}

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}

export default App;
