import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Index from "../component/neorace/Index";
import Stage from "../component/neorace/Stage";
import { getUser, getImage } from "../utils/Common";
import Mission from "../component/neorace/Mission";
import Checkpoint from "../component/neorace/Checkpoint";
class NeoRace extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const user = getUser();
    const imgae = getImage();
    return (
      <div className="full">
        {/* <BadgePicture dataUser={user} image={imgae}/> */}
        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src={
              this.props.image != null
                ? this.props.image
                : "images/avatars/2s.png"
            }
          />
        </div>
        <BrowserRouter>
          <Switch>
            <Route path="/Neorace" exact component={Index} />
            <Route path="/Neorace/stage" exact component={Stage} />
            <Route path="/Neorace/mission" component={Mission} />
            <Route path="/Neorace/checkpoint" component={Checkpoint} />
          </Switch>
        </BrowserRouter>
        {/*  */}
      </div>
    );
  }
}

export default NeoRace;
