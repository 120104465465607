import React, { Component } from "react";

class BadgesItemAll extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onClick = () => {
    window.$("#menu-success-1").showMenu();
    this.props.getData(this.props.dataBadges);
    window.$("#menu-success-1").appendTo("body");
  };
  render() {
    return (
      <div
        className="text-center col-3"
        style={{ display: this.props.myBadge, marginBottom: "12px" }}
        onClick={() => this.onClick()}
      >
        <a href="#">
          <img
            src={
              this.props.dataBadges.imgurl != null
                ? this.props.dataBadges.imgurl
                : "https://neo.deprakoso.com/assets/badges/Learner.png"
            }
            width={55}
            height={55}
            className="rounded-xl owl-lazy shadow-l bg-gradient-red1"
          />
        </a>
        <a> </a>
        <br />
        {/* <marquee >{this.props.dataBadges.name}</marquee> */}
      </div>
    );
  }
}

export default BadgesItemAll;
