import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Index from "../component/classroom/Index";
import Explore from "../component/classroom/Explore";
import Myclass from "../component/classroom/Myclass";
import ViewGridExplore from "../component/classroom/ViewGridExplore";
import Info from "../component/classroom/Info";
import PageApp from "../component/classroom/PageApp";
import Playbook from "./Playbook";
class Classroom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        {/* <BadgeFoto dataUser={user} image={image} /> */}
        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src={this.props.image != null ? this.props.image : "/images/avatars/2s.png"}
          />
        </div>
        
        <BrowserRouter>
          <Switch>
            <Route path="/Classroom" exact component={Index} />
            <Route path="/Classroom/viewexplore" component={ViewGridExplore} />
            <Route path="/Classroom/myclass" component={Myclass} />
            <Route path="/Classroom/explore" component={Explore} />
            <Route path="/Classroom/info" component={Info} />
            <Route path="/Classroom/app" component={PageApp} />
            <Route path="/Classroom/playbook" component={Playbook} />
            
          </Switch>
          
        </BrowserRouter>
      </div>
    );
  }
}

export default Classroom;
