import React, { Component } from "react";
import $ from "jquery";
import { getImage, getUser } from "../utils/Common";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { isBrowser } from "react-device-detect";
class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = { namaHeader: "" };
  }

  getUrl() {
    var getUrl = window.location.href.split("/");
    let q = queryString.parse(this.props.location.search);
    if (getUrl.length == 4) {
      let str = getUrl[3];
      let outStr = str.replace(/[^\w\s]/g, "");

      this.setState({
        namaHeader:
          getUrl[3] != "Customize"
            ? outStr == "dashboard"
              ? "HOME"
              : outStr.toUpperCase()
            : "MY PROFILE",
      });
    } else if (getUrl.length > 4) {
      let str = getUrl[4];
      let outStr = str.replace(/[^\w\s]/g, "");
      if (getUrl[4].includes("viewexplore")) {
        this.setState({
          namaHeader: "Explore",
        });
      } else if (getUrl[3].includes("dashboard#")) {
        this.setState({ namaHeader: "HOME" });
      } else if (getUrl[3].includes("Customize")) {
        this.setState({ namaHeader: "My Profile" });
      } else if (getUrl[4].includes("checkpoint")) {
        this.setState({ namaHeader: "NEO MISSION" });
      } else if (getUrl[3].includes("Profile")) {
        this.setState({ namaHeader: "Profile" });
      } else {
        this.setState({
          namaHeader:
            outStr.toUpperCase() == "APP"
              ? "MY APPS"
              : getUrl[4].includes("stage")
              ? `STAGE ${q.stage}`
              : outStr.toUpperCase(),
        });
      }
    }
  }
  componentDidMount() {
    this.getUrl();
  }

  profile = () => {
    let q = queryString.parse(this.props.location.search);
    if (this.state.namaHeader == "HOME") {
      return (
        <div>
          <div className="page-title page-title-small">
            <h2>
              {getUser()[0].nama} <br />
              <small className="font-200">{getUser()[0].jabatan}</small>
            </h2>
            <img
              data-menu="menu-main"
              className="bg-fade-gray1-dark shadow-xl preload-img"
              data-src={
                getUser()[0].propic == null
                  ? "images/avatars/5s.png"
                  : getUser()[0].propic
              }
            />
          </div>
          {isBrowser ? (
            <div className="page-title page-title-small retain-title">
              <h2>
                {this.state.namaHeader} <br />
              </h2>
              <img
                data-menu="menu-main"
                className="bg-fade-gray1-dark shadow-xl preload-img d-md-none"
                data-src={
                  getUser()[0].propic == null
                    ? "images/avatars/5s.png"
                    : getUser()[0].propic
                }
              />
            </div>
          ) : (
            ""
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div className="page-title page-title-small">
            <h2>
              {this.state.namaHeader == "NEO MISSION" ||
              this.state.namaHeader == `STAGE ${q.stage}` ? (
                <a href={"#"} data-back-button>
                  <i className="fa fa-arrow-left" />
                </a>
              ) : (
                ""
              )}

              {this.state.namaHeader}
            </h2>
            <img
              data-menu="menu-main"
              className="bg-fade-gray1-dark shadow-xl preload-img"
              data-src={
                getUser()[0].propic == null
                  ? "/images/avatars/5s.png"
                  : getUser()[0].propic
              }
            />
          </div>
        </div>
      );
    }
  };

  render() {
    const image = getImage();
    return this.profile();
  }
}

export default withRouter(TopBar);
