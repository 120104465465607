import React, { Component } from "react";
class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataimage: "",
      picture: this.props.props.dataJSON.answer,
      dataInput: "",
    };
    // console.log(this.props.no);
  }
  clickimage = () => {
    document.getElementById("uploadimage_" + this.props.no).click();
  }

  uploadImage(e, cb) {
    if (e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          picture: reader.result,
        });
        cb(reader.result);
      };
      this.setState({ dataimage: e.target.files[0] });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  update = (e) => {
    this.setState({ dataInput: { id: this.props.props.id, answer: e } });
  };

  render() {
    return (
      <div>
        <div className="input-style input-style-2 input-required text-left">
          <label>{this.props.no + "." + this.props.props.dataJSON.name}</label>
          <label>{this.props.props.dataJSON.note}</label>
          <div className="row text-center mb-0">
            <div
              className="col-12 pl-0 pr-0"
              style={{ cursor: "pointer" }}
              onClick={this.clickimage}
            >
              <div className="card card-style">
                <img src={this.state.picture} className="img-fluid" />
                <div className="content pb-0">
                  <center>
                    <h1 className="center-text pt-4">
                      <i className={"fa fa-file"}></i>
                    </h1>
                    Upload File
                    <p>{this.state.dataimage.name}</p>
                    <input
                      type="file"
                      id={`uploadimage_` + this.props.no}
                      hidden
                      accept="image/x-png,image/gif,image/jpeg"
                      onChange={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        let Basee64 = "";
                        if (e.target.files[0]) {
                          this.uploadImage(e, (result) => {
                            Basee64 = result;
                            this.props.props.onChange({
                              id: this.props.props.dataJSON.id,
                              answer: Basee64,
                            });
                          });
                        }
                      }}
                    />
                  </center>
                  <p />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadFile;
