import React, { Component } from "react";
import { getUser, getCeo } from "../utils/Common";
import ModalCeo from "../component/ModalCeo";
import { Link } from "react-router-dom";
import {
  getMyBadges,
  getVideoExplore,
  getRecentActivities,
} from "../utils/Repository";
import MyBadges from "../component/profile/MyBadges";
import ExploreList from "../component/profile/ExploreList";
import RecenrActivitiy from "../component/classroom/component/RecenrActivitiy";
import io from "socket.io-client";
import { isMobile } from "react-device-detect";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myBadges: [],
      explore: [],
      badge: [],
      category: "",
      dataActivity: [],
      socket: io(`https://neo.deprakoso.com`),
      msg: "",
    };
  }

  getMyBadges() {
    return getMyBadges(getUser()[0].nik)
      .then((resp) => resp.data)
      .then((data) => this.setState({ myBadges: data.result }))
      .catch((error) => console.log(error));
  }

  ExploreList() {
    return getVideoExplore("business_knowledge")
      .then((resp) => resp.data)
      .then((data) =>
        this.setState({
          explore: data.result.videos,
          category: data.result.category,
        })
      )
      .catch((error) => console.log(error));
  }

  componentDidMount() {
    this.getMyBadges();
    this.ExploreList();
    this.fecth();
  }


  getDataBadgeComponent(data) {
    this.setState({ badge: data });
  }

  fecth = () => {
    let dataform = new FormData();
    dataform.append("nik", getUser()[0].nik);
    dataform.append("regdate", getUser()[0].regdate);
    return getRecentActivities(dataform)
      .then((resp) => resp.data)
      .then((data) => data.result)
      .then((dataActivity) => this.setState({ dataActivity: dataActivity }));
  };

  render() {
    const video = getCeo();
    const listmyBadges = this.state.myBadges && this.state.myBadges.map((data, index) => {
      if (index < 5 && isMobile) {
        return (
          <MyBadges
            dataBadges={data}
            key={index}
            getData={this.getDataBadgeComponent.bind(this)}
          />
        );
      } else {
        return (
          <MyBadges
            dataBadges={data}
            key={index}
            getData={this.getDataBadgeComponent.bind(this)}
          />
        );
      }
    });

    const listExpolre = this.state.explore.map((data, index) => {
      if (index < 6) {
        return (
          <ExploreList
            dataExplore={data}
            key={index}
            category={this.state.category}
          />
        );
      }
    });

    const list = this.state.dataActivity && this.state.dataActivity.map((data, index) => (
      <RecenrActivitiy dataActivity={data} key={index} />
    ));

    return (
      <div>
        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src={
              this.props.image != null
                ? this.props.image
                : "images/avatars/2s.png"
            }
          />
        </div>
        <div className="single-slider-boxed2 text-center owl-no-dots owl-carousel">
          <div className="card rounded-l shadow-l" data-card-height={320}>
            <Link to="/Neorace">
              <div className="card-bottom">
                <h1 className="font-24 font-700">Neo Race</h1>
                <p className="boxed-text-xl">
                  Challenge yourself on race stages with multiple missions
                  inside.
                </p>
              </div>
              <div className="card-overlay bg-gradient-fade" />
              <div
                className="card-bg owl-lazy"
                data-src="images/pictures/17m.jpg"
              />
            </Link>
          </div>
          <div className="card rounded-l shadow-l" data-card-height={320}>
            <Link to="/Classroom/explore">
              <div className="card-bottom">
                <h1 className="font-24 font-700">Explore</h1>
                <p className="boxed-text-xl">
                  Learn more about Indosat PT.Tbk.
                </p>
              </div>
              <div className="card-overlay bg-gradient-fade" />
              <div
                className="card-bg owl-lazy"
                data-src="images/pictures/8m.jpg"
              />
            </Link>
          </div>
          <div className="card rounded-l shadow-l" data-card-height={320}>
            <Link to="/Classroom">
              <div className="card-bottom">
                <h1 className="font-24 font-700">Classroom</h1>
                <p className="boxed-text-xl">
                  Connect with your teammate and tutor.
                </p>
              </div>
              <div className="card-overlay bg-gradient-fade" />
              <div
                className="card-bg owl-lazy"
                data-src="images/pictures/14m.jpg"
              />
            </Link>
          </div>
        </div>
        <div className="divider-icon divider-margins mb-4 mt-2 bg-highlight">
          <i className="fa font-17 color-yellow1-dark fa-lightbulb" />
        </div>

        <div className="content mt-0 mb-2">
          <h5 className="float-left font-16 font-500">Watch and learn. </h5>
          <Link
            className="float-right font-12 color-highlight mt-n1"
            to="/Classroom/explore"
          >
            View All
          </Link>
          <div className="clearfix" />
        </div>
        <div className="double-slider mb-4 owl-carousel owl-no-dots ">
          {listExpolre}
        </div>
        <div className="row">
          <div className="col-12 col-md-6 pr-md-0">
            <div className="divider-icon divider-margins mb-4 mt-5 bg-highlight">
              <i className="fa font-17 color-yellow1-dark fa-medal" />
            </div>
            <div className="content mt-0 mb-2">
              <h5 className="float-left font-16 font-500">Badges. </h5>
              <Link
                className="float-right font-12 color-highlight mt-n1"
                to="/Allbadges"
              >
                View All
              </Link>
              <div className="clearfix" />
            </div>
            {isMobile ? (
              <div className="card card-style">
                <div
                  className="content"
                  style={{ height: "100px", overflowY: "auto" }}
                >
                  <h5>
                    <small>
                      {" "}
                      Here are list of badges you've earned so far.
                    </small>
                  </h5>
                  <div className="user-list-slider owl-carousel mt-0 mb-n1">
                    {listmyBadges}
                  </div>
                </div>
              </div>
            ) : (
              <div className="card card-style">
                <div
                  className="content user-list-badge"
                  style={{ height: "310px", overflowY: "auto" }}
                >
                  {listmyBadges}
                </div>
              </div>
            )}
          </div>
          <div className="col-12 col-md-6 pr-md-0">
            <div className="divider-icon divider-margins mb-4 mt-5 bg-highlight">
              <i className="fa font-17 color-yellow1-dark fa-history" />
            </div>
            <div className="content mt-0 mb-2">
              <h5 className="float-left font-16 font-500">
                Recent Activities{" "}
              </h5>
              <Link
                className="float-right font-12 color-highlight mt-n1"
                to="/Classroom/myclass"
              >
                View All
              </Link>
              <div className="clearfix" />
            </div>
            <div className="card card-style mb-0">
              <div
                className="content"
                style={{ height: "310px", overflowY: "auto" }}
              >
                {list && list?.length != 0 ? (
                  list
                ) : (
                  <p className="text-center">No Activity</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <BadgeModal datadetail={this.state.badge} /> */}
        <div
          id="menu-success-1"
          className="menu menu-box-modal rounded-m"
          data-menu-width={310}
        >
          <div className="clearfix mb-n3" />
          <h1 className="text-center mt-5 font-700">{this.state.badge.name}</h1>
          <p className="boxed-text-l">{this.state.badge.description}</p>
          <div className="clearfix mb-n1" />
          <div className="pl-5">
            <a className="text-center pt-3 float-left">
              <img
                src={this.state.badge.imgurl}
                width={55}
                height={55}
                className="mx-2 rounded-xl owl-lazy shadow-l bg-gradient-dark1"
              />
              <p>Platinum</p>
            </a>
            <a className="text-center pt-3 float-left">
              <img
                src={this.state.badge.imgurl}
                width={55}
                height={55}
                className="mx-2 rounded-xl owl-lazy shadow-l bg-gradient-yellow1"
              />
              <p>Gold</p>
            </a>
            <a className="text-center pt-3 float-left">
              <img
                src={this.state.badge.imgurl}
                width={55}
                height={55}
                className="mx-2 rounded-xl owl-lazy shadow-l bg-gradient-gray2"
              />
              <p>Silver</p>
            </a>
          </div>
          <div className="clearfix mb-n1" />
          <a
            href="#"
            style={{ marginBottom: "20px" }}
            className={`close-menu rounded-xl btn btn-m btn-center-m button-s shadow-l rounded-s text-uppercase font-900 bg-red1-dark`}
          >
            Close
          </a>
        </div>
        {video == "true" ? "" : <ModalCeo />}
      </div>
    );
  }
}

export default Profile;
