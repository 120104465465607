import React, { Component } from "react";
import EditPicture from "../component/profile/EditPicture";
import BadgeFoto from "../component/profile/BadgeFoto";
import { getUser, getImage } from "../utils/Common";
class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const user = getUser();
    const image = getImage();
    return (
      <div>
        <BadgeFoto dataUser={user} image={image} />
        <EditPicture />
      </div>
    );
  }
}

export default EditProfile;
