import React, { Component } from "react";
import Contact from "../component/profile/Contact";
import { getUser } from "../utils/Common";
import ContactBuddy from "../component/profile/ContactBuddy";
import { getContact } from "../utils/Repository";
import ModalContact from "../component/profile/ModalContact";
import ModalContactBuddy from "../component/profile/ModalContactBuddy";

class ImportantContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataContact: [],
      secretary: [],
      buddyName: "",
      buddyPhone: "",
      buddyEmail: "",
      pmName: "",
      pmPhone: "",
      pmEmail: "",
      secretaryPhone: "",
      secretaryEmail: "",
      dataContactPerson: [],
      type: "",
      PmOrBuddy: "",
      dataPMandBuddy: [],
    };
  }

  getDataContactPerson(data) {
    this.setState({ dataContactPerson: data });
    console.log(data);
  }
  getType(type) {
    this.setState({ type: type });
  }

  getPmOrBuddy(data) {
    this.setState({ PmOrBuddy: data });
  }

  fetch = () => {
    let data = new FormData();
    data.append("nik", getUser()[0].nik);
    return getContact()
      .then((resp) => resp.data)
      .then((data) => data.result)
      .then((dataCon) => {
        const obj = [
          {
            email: dataCon.dynamic.manager_email,
            phone: dataCon.dynamic.manager_phone,
            name: dataCon.dynamic.manager_name,
            role: "People Manager",
            id: "pm",
          },
          {
            email: dataCon.dynamic.buddy_email,
            phone: dataCon.dynamic.buddy_phone,
            name: dataCon.dynamic.buddy_name,
            role: "Buddy",
            id: "buddy",
          },
        ];
        this.setState({
          dataContact: dataCon.static,
          secretary: dataCon.dynamic.secretary,
          buddyName: dataCon.dynamic.buddy_name,
          buddyPhone: dataCon.dynamic.buddy_phone,
          buddyEmail: dataCon.dynamic.buddy_email,
          pmName: dataCon.dynamic.manager_name,
          pmPhone: dataCon.dynamic.manager_phone,
          pmEmail: dataCon.dynamic.manager_email,
          dataPMandBuddy: obj,
        });
      });
  };
  componentDidMount() {
    this.fetch();
  }

  render() {
    const listContact = this.state.dataContact.map((data, index) => (
      <Contact
        dataContact={data}
        phone={data.phone.replace("0", "+62")}
        key={index}
        getData={this.getDataContactPerson.bind(this)}
        type={this.getType.bind(this)}
      />
    ));

    const listBudyPm = this.state.dataPMandBuddy.map((data, index) => (
      <ContactBuddy
        name={data.name}
        email={data.email}
        phone={data.phone}
        role={data.role}
        key={index}
        id={data.id}
        // dataContact={data}
        getData={this.getDataContactPerson.bind(this)}
        type={this.getType.bind(this)}
      />
    ));

    const listContactsecretary = this.state.secretary.map((data, index) => (
      <ContactBuddy
        name={data.secretary_name}
        email={data.secretary_email}
        phone={data.secretary_phone}
        role={"secretary"}
        key={index}
        id={`secretary${index}`}
        getData={this.getDataContactPerson.bind(this)}
        type={this.getType.bind(this)}
        PmOrBuddy={this.getPmOrBuddy.bind(this)}
      />
    ));

    return (
      <div>
        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src="images/pictures/20s.jpg"
          />
        </div>
        <div className="card card-style">
          <div className="content mt-0 pt-2 mb-2">
            <div className="accordion" id="accordion-1">
              {listBudyPm}
              {listContactsecretary}
            </div>
          </div>
        </div>
        <div className="card card-style">
          <div className="content mt-0 pt-2 mb-2">
            <div className="accordion" id="accordion-2">
              {listContact}
            </div>
          </div>
        </div>
        <ModalContact
          getDataContactPerson={this.state.dataContactPerson}
          jenis={this.state.type}
        />
        <ModalContactBuddy
          jenis={this.state.type}
          getDataContactPerson={this.state.dataContactPerson}
        />
      </div>
    );
  }
}

export default ImportantContact;
