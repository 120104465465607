import React, { Component } from "react";
import moment from "moment";
const ListHistory = ({ dataHistory }) => {
  return (
    <div>
      <div className="clearfix" />

      <div
        className="speech-bubble color-white bg-gray3-dark"
        style={{ maxWidth: "100%!important" }}
      >
        {dataHistory.title}
        <br />
        <p className="float-right mb-0 font-11" style={{ color: "white" }}>
          {moment(dataHistory.updateDateHistory, "YYYYMMDD").fromNow()}
        </p>
      </div>
    </div>

    // <a href="#">
    //   <i className="fas fa-play rounded-xl shadow-s bg-gray2-dark" />
    //   <span>{dataHistory.title}</span>
    //   <strong>{dataHistory.updateDateHistory}</strong>
    // </a>
  );
};

export default ListHistory;
