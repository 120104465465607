import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import { getUser } from "../../../utils/Common";
import ReactTooltip from "react-tooltip";
import { getContentCompletion } from "../../../utils/Repository";

class CardModule extends Component {
  state = { urlvideo: "" };
  viewPDF = (url, content_id) => {
    let formdata = new FormData();
    formdata.append("user_id", getUser()[0].nik);
    formdata.append("content_id", content_id);
    return getContentCompletion(formdata).then((response) => {
      if (response.status == 200) {
        window.open(url);
      }
    });
  };

  limitChar(text, count) {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  }
  playVideo = (cat, url, content_id, duration) => {
    var getUrl = url.split("/");
    window.location.href =
      "/playvideo?cat=" +
      cat +
      "&vid=" +
      getUrl[3] +
      "&duration=" +
      duration +
      "&content_id=" +
      content_id;
  };
  render() {
    return (
      <div className="item">
        <div className="card card-style">
          <div className="card" data-card-height={250}>
            <img
              className="card-img-top"
              height={"250"}
              src={this.props.dataJSON.imageVideoUrl}
              alt="Card image cap"
            />
            <div className="card-bottom pb-0 px-3">
              <h1 className="font-10">
                <span>
                  <span className="opacity-80">
                    <i className="fa fa-heart color-red1-dark" />{" "}
                    {this.props.dataJSON.likeCount} &nbsp;{" "}
                    <i
                      className="color-green1-dark fa fa-check"
                      style={
                        this.props.dataJSON.completionState != "TRUE"
                          ? { display: "none" }
                          : {}
                      }
                    />{" "}
                    {this.props.dataJSON.completionState == "TRUE"
                      ? "Completed"
                      : ""}
                  </span>
                </span>
                <span className="float-right">
                  <i className="far fa-clock">
                    {this.props.dataJSON.durationString}
                  </i>
                </span>
              </h1>
            </div>
            <div className="card-overlay bg-gradient-fade rounded-0" />
          </div>
          <div className="card-body">
            <h5 data-tip data-for={this.props.dataJSON.id}>
              {this.limitChar(this.props.dataJSON.title, 35)}
            </h5>
            <ReactTooltip
              id={this.props.dataJSON.id}
              effect="solid"
              place="bottom"
            >
              <span>{this.props.dataJSON.title}</span>
            </ReactTooltip>
            <div style={{ height: "50px", overflowY: "auto" }}>
              <p className="card-text">{this.props.dataJSON.caption}</p>
            </div>

            <div
              data-toggle={
                this.props.pdf
                  ? ""
                  : this.props.dataJSON.lastDuration == null
                  ? ""
                  : "modal"
              }
              data-target={
                this.props.pdf
                  ? ""
                  : this.props.dataJSON.lastDuration == null
                  ? ""
                  : "#modalConfirm"
              }
              onClick={
                this.props.pdf
                  ? () =>
                      this.viewPDF(
                        this.props.dataJSON.pathUrl,
                        this.props.dataJSON.id
                      )
                  : this.props.dataJSON.lastDuration == null
                  ? () =>
                      this.playVideo(
                        this.props.category,
                        this.props.dataJSON.url,
                        this.props.dataJSON.id,
                        "0"
                      )
                  : () => this.props.getUrlVideo(this.props.dataJSON)
              }
              className={
                this.props.pdf
                  ? "btn btn-m mt-2 mb-4 btn-full bg-red1-dark rounded-xl text-uppercase font-900"
                  : this.props.dataJSON.lastDuration == null
                  ? "btn btn-m mt-2 mb-4 btn-full bg-red1-dark rounded-xl text-uppercase font-900"
                  : "btn btn-m mt-2 mb-4 btn-full bg-yellow1-dark rounded-xl text-uppercase font-900"
              }
            >
              {this.props.pdf
                ? "VIEW"
                : this.props.dataJSON.lastDuration == null
                ? "WATCH"
                : "WATCH AGAIN"}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CardModule);
