import React, { Component } from "react";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";
class ExploreList extends Component {
  constructor(props) {
    super(props);
    this.state = { height: "20vh" };
  }
  limitChar(text, count) {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  }
  onClick(data) {
    var getUrl = data.url.split("/");
    window.location.href =
      "/playvideo?cat=" +
      this.props.category +
      "&vid=" +
      getUrl[3] +
      "&duration=0&content_id=" +
      data.id;
  }

  render() {
    return (
      <div
        className="item bg-theme pb-3 rounded-m shadow-l"
        onClick={() => this.onClick(this.props.dataExplore)}
      >
        <div className={`card mb-1`} style={{ height: "auto" }}>
          <img
            className="card-img-top"
            src={this.props.dataExplore.imageVideoUrl}
            alt="Card image cap"
          />
          <div className="card-bottom">
            <h5 className="color-white text-center pr-2 pb-2">
              <small>{this.limitChar(this.props.dataExplore.title, 15)}</small>
            </h5>
          </div>
          <div className="card-overlay bg-gradient" />
        </div>

        <div className="d-flex px-3 text-center">
          <div className="text-center" style={{ width: "100%" }}>
            <h5>
              <small style={{ width: "100%" }}>
                <small>
                  &nbsp;&nbsp;
                  <i className="far fa-clock" />
                  &nbsp;&nbsp;
                  {this.props.dataExplore.durationString}
                  &nbsp;&nbsp; | &nbsp;&nbsp;
                  <span className="opacity-80">
                    <i className="fa fa-heart color-red1-dark" />
                    &nbsp;&nbsp;&nbsp;
                    {this.props.dataExplore.likeCount} &nbsp;&nbsp;&nbsp;
                  </span>
                </small>
              </small>
            </h5>
            <a href="#" className="color-red1-dark">
              <b> Watch Video</b>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default ExploreList;
