import React from "react";
import { withRouter, Link } from "react-router-dom";
import { getUser } from "../../../utils/Common";

const ListLeaderBoard = ({ dataContact, key }) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  return (
    <div className="mb-0">
      <button
        className="btn pl-0 pr-0 accordion-btn"
        data-toggle="collapse"
        data-target={`#collapse${dataContact.nik}`}
        aria-expanded="false"
      >
        <div className="user-left">
          <div className="d-flex">
            <div>
              <img
                src={
                  dataContact.avatarImg ==
                    "https://neo.deprakoso.com/assets/userimages/" ||
                  dataContact.avatarImg == null
                    ? "/images/avatars/avatar kunning.png"
                    : dataContact.avatarImg
                }
                className="mr-3 rounded-circle shadow-l"
                width={40}
                height={40}
                alt="description of image1"
              />
            </div>
            <div>
              <h5 className="mt-1 mb-0">{dataContact?.nama?.toProperCase() || ""}</h5>
              <p className="font-10 mt-n1 color-gray2-dark">
                {dataContact.jabatan}
              </p>
            </div>
            <div className="ml-auto">
              <span className="font-15">0 pts</span>
            </div>
          </div>
        </div>
      </button>
      <div
        id={`collapse${dataContact.nik}`}
        className="collapse"
        data-parent="#accordion-1"
      >
        <div className="pt-1 pb-2 pl-3 pr-3">
          <div className="d-flex">
            <div className="col-3">Phone</div>
            <div className="col-9">
              {dataContact.im3_postpaid == "" ||
              dataContact.im3_postpaid == null
                ? "-"
                : dataContact.im3_postpaid + "(Postpaid) "}
              {dataContact.im3_prepaid == "" || dataContact.im3_prepaid == null
                ? ""
                : dataContact.im3_prepaid + "(Prepaid)"}
            </div>
          </div>
          <div className="d-flex">
            <div className="col-3">Email</div>
            <div className="col-9">
              {dataContact.email == null ? "-" : dataContact.email}
            </div>
          </div>
          <div>
            <div className="text-center pt-2">
              <Link
                to={`/Profile/${dataContact.nik}`}
                className="icon icon-xs mr-1 rounded-xl bg-red1-dark"
              >
                <i className="fas fa-user" />
              </Link>
              <a
                href={
                  dataContact.twitter == null
                    ? "https://twitter.com/login?lang=id"
                    : dataContact.twitter
                }
                className="icon icon-xs mr-1 rounded-xl bg-red1-dark"
              >
                <i className="fab fa-twitter" />
              </a>
              <a
                href={
                  dataContact.instagram == null
                    ? "https://www.instagram.com/?hl=id"
                    : dataContact.instagram
                }
                className="icon icon-xs mr-1 rounded-xl bg-red1-dark"
              >
                <i className="fab fa-instagram" />
              </a>
              <a
                href={
                  dataContact.linkedin == null
                    ? "https://www.linkedin.com/"
                    : dataContact.linkedin
                }
                className="icon icon-xs mr-1 rounded-xl bg-red1-dark"
              >
                <i className="fab fa-linkedin-in" />
              </a>
              <a
                href={
                  dataContact.workplace == null
                    ? "https://work.workplace.com/"
                    : dataContact.workplace
                }
                className="icon icon-xs mr-1 rounded-xl bg-red1-dark"
              >
                <img
                  src={"/images/icons/workplace-white.png"}
                  className="rounded-circle shadow-l"
                  style={{ margin: "5px" }}
                  width={25}
                  height={25}
                  alt="description of image1"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(ListLeaderBoard);
