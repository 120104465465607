import React, { Component } from "react";
import Checklist from "./Checklist";
import Essay from "./Essay";
import MapCard from "./MapCard";
import UploadFile from "./UploadFile";
import ReactTextFormat from "react-text-format";
class ListInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choices: [],
      latitude: "-6.200000",
      longitude: "106.816666",
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }
  componentDidMount() {
    this.setState({ choices: this.props.dataJSON.choices });
  }

  handleOnChange(e) {
    var value = e.target.value;
    if (this.props.checked == "on") {
      value = false;
    }
    console.log(value);
  }

  render() {
    if (this.props.dataJSON.type == "checklist") {
      return <Checklist props={this.props} no={this.props.no} />;
    } else if (this.props.dataJSON.type == "upload_form") {
      return <UploadFile props={this.props} no={this.props.no} />;
    } else if (this.props.dataJSON.type == "essay") {
      return <Essay props={this.props} no={this.props.no} />;
    } else if (this.props.dataJSON.type == "map") {
      return <MapCard props={this.props} no={this.props.no} />;
    } else if (this.props.dataJSON.type == "multiple_choice") {
      const list = this.state.choices.map((data, index) => (
        <div className="fac fac-radio fac-red" key={`${data}${index}`}>
          <span></span>
          <input
            id={`${data}-fac-radio`}
            type="radio"
            name={`radio${this.props.dataJSON.id}`}
            value={data}
            defaultChecked={data == this.props.dataJSON.answer ? true : false}
            onChange={(e) =>
              this.props.onChange({
                id: this.props.dataJSON.id,
                answer: e.target.value,
              })
            }
          />
          <label htmlFor={`${data}-fac-radio`}>{data}</label>
        </div>
      ));
      return (
        <div>
          <label>
            {this.props.no + "."}
            <ReactTextFormat>{this.props.dataJSON.name}</ReactTextFormat>
          </label>
          <div className="col-12 mb-4">{list}</div>
        </div>
      );
    }
  }
}

export default ListInput;
