import React, { Component } from "react";
import Axios from "axios";
import { getPlaybook } from "../utils/Repository";

class Playbook extends Component {
  constructor(props) {
    super(props);
    this.state = { dataPlaybook: [] };
  }

  getApi = () => {
    return getPlaybook()
      .then((resp) => resp.data)
      .then((data) => this.setState({ dataPlaybook: data.result }))
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    this.getApi();
  }
  render() {
    const list = this.state.dataPlaybook.map((data, index) => (
      <a href={data.url} target="_blank" key={index}>
        <i className="fa fa-book color-red2-dark" />
        <span className="font-14">{data.title}</span>
        <i className="fa fa-angle-right" />
      </a>
    ));
    return (
      <div className="card card-style">
        <div className="content mt-0 mb-2">
          <div className="list-group list-custom-small list-icon-0">{list}</div>
        </div>
      </div>
    );
  }
}

export default Playbook;
