import React, { Component } from "react";
import LeaderBoard from "./LeaderBoard";
import Activity from "./Activity";

class Myclass extends Component {
  constructor(props) {
    super(props);
    this.state = { dataContact: [], bottomchat: "false", update: false };
  }

  onclick = (bool) => {
    this.setState({ bottomchat: bool });
  };

  render() {
    return (
      <div className="card card-style bg-theme pb-0 mb-n3 history-list">
        <div className="content">
          <div
            className="tab-controls  tab-animated tabs-medium  shadow-xl"
            data-tab-items={2}
            data-tab-active="bg-yellow1-dark"
          >
            <a
              href="#!"
              data-tab-active
              data-tab="tab-1"
              style={{
                borderBottomLeftRadius: "20px",
                borderTopLeftRadius: "20px",
              }}
              onClick={() => this.onclick("")}
            >
              <p className={"color-black"}>Leaderboard</p>
            </a>
            <a
              href="#!"
              data-tab="tab-2"
              style={{
                borderBottomRightRadius: "20px",
                borderTopRightRadius: "20px",
              }}
              onClick={() => this.onclick("")}
            >
              <p className={"color-black"}>Activities</p>
            </a>
            
          </div>
          <div className="clearfix mb-3"></div>
          <LeaderBoard />
          <Activity />
          {/* <ChatRoom bottomchat={this.state.bottomchat} /> */}
        </div>
      </div>
    );
  }
}

export default Myclass;
