import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  removeUserSession,
  getUser,
  getToken,
  getImage,
  getRole,
} from "../utils/Common";
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = { userdata: [] };
  }

  handleLogout = () => {
    removeUserSession();
    this.props.history.push("/");
  };

  render() {
    const user = getUser();
    const image = getImage();
    const role = getRole();
    return (
      <div>
        <div className="menu-header">
          <a href="#" data-toggle-theme className="border-right-0">
            <i className="fa font-12 color-yellow1-dark fa-lightbulb" />
          </a>
          <a href="#" className="border-right-0">
            <i className="fa font-12 color-red2-dark fa-times" />
          </a>
        </div>
        <div className="menu-logo text-center">
          <a href="#">
            <img
              className="rounded-circle bg-highlight"
              width={80}
              height={80}
              src={
                getUser()[0].propic != null
                  ? getUser()[0].propic
                  : "/images/avatars/5s.png"
              }
            />
          </a>
          <h1 className="pt-3 font-800 font-15 text-uppercase">
            {user[0].nama}
          </h1>
          <p className="font-12 mt-n2">{user[0].jabatan}</p>
        </div>
        <div className="menu-items">
          <h5 className="text-uppercase opacity-20 font-12 pl-3">Menu</h5>
          {/* <Link to="/EditProfile">
            <i
              data-feather="user"
              data-feather-line={1}
              data-feather-size={21}
              data-feather-color="red2-dark"
              data-feather-bg="red2-fade-light"
            />
            <span>Profile</span>
            <i className="fa fa-circle" />
          </Link> */}
          <Link to="/Customize">
            <i className={"far fa-id-badge color-red1-dark"} />
            <span>My Profile</span>
            <i className="fa fa-circle" />
          </Link>
          <Link id="nav-welcome" to="/Contact">
            <i
              data-feather="users"
              data-feather-line={1}
              data-feather-size={16}
              data-feather-color="indosat-merah-dark"
              data-feather-bg="red2-fade-dark"
            />
            <span>Important Contact</span>
            <i className="fa fa-circle" />
          </Link>
          <Link id="nav-welcome" to="/History">
            <i
              data-feather="rotate-ccw"
              data-feather-line={1}
              data-feather-size={16}
              data-feather-color="indosat-merah-dark"
              data-feather-bg="red2-fade-dark"
            />
            <span>History</span>
            <i className="fa fa-circle" />
          </Link>
          <Link to="/Apps">
            <i className={"fa fa-mobile-alt color-red1-dark"} />
            <span>App</span>
            <i className="fa fa-circle" />
          </Link>
          {/* {role == "admin" ? (
            <Link to="/chatbatch">
              <i
                data-feather="message-circle"
                data-feather-line={1}
                data-feather-size={16}
                data-feather-color="indosat-merah-dark"
              />
              <span>Chat Batch</span>
              <i className="fa fa-circle" />
            </Link>
          ) : (
              ""
            )} */}
          <div
            style={{ marginLeft: "20px" }}
            id="nav-starters"
            onClick={this.handleLogout}
          >
            <i
              data-feather="log-out"
              data-feather-line={1}
              data-feather-size={16}
              data-feather-color="red2-dark"
              data-feather-bg="red2-fade-dark"
            />
            <span style={{ marginLeft: "-35px" }}>Logout</span>
            <i className="fa fa-circle" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Menu);
