import React, { Component } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import {
  removeUserSession,
  getUser,
  getToken,
  getImage,
  getRole,
} from "../utils/Common.js";
class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { home: true, classroom: false, neorace: false };
  }
  handleLogout = () => {
    removeUserSession();
    window.location.href = "/";
  };

  activeMenu = (menu) => (e) => {
    e.preventDefault();
    if (menu == "dashboard") {
      this.setState({ home: true, classroom: false, neorace: false });
      console.log("test1");
      window.location.assign("/dashboard");
    } else if (menu == "classroom") {
      this.setState({ home: false, classroom: true, neorace: false });
      console.log("test2");
      window.location.assign("/Classroom");
    } else if (menu == "neorace") {
      this.setState({ home: false, classroom: false, neorace: true });
      console.log("test3");
      window.location.assign("/Neorace");
    }
  };
  render() {
    const user = getUser();
    var isActive = window.location.href.split("/").reverse()[0];

    return (
      <nav id="sidebarMenu" className="sidebar collapse">
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 my-3 text-muted">
          <a href="#" className="header-title header-subtitle text-muted">
            New Employee Orientation
          </a>
        </h6>
        <div className="sidebar-menu">
          <div className="accordion" id="profileDrawer">
            <div className="card">
              <div className="card-header p-0" id="profileDraw">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-profile"
                    type="button"
                    data-toggle="collapse"
                    data-target="#profileMenu"
                    aria-expanded="false"
                    aria-controls="profileMenu"
                  >
                    <div className="menu-logo text-center">
                      <a href="#">
                        <img
                          className="rounded-circle bg-highlight"
                          width={80}
                          height={80}
                          src={
                            getUser()[0].propic != null
                              ? getUser()[0].propic
                              : "/images/avatars/5s.png"
                          }
                        />
                      </a>
                      <h1 className="pt-3 font-800 font-15 text-uppercase">
                        {user[0].nama}
                      </h1>
                      <p className="font-12 mb-0">{user[0].jabatan}</p>
                    </div>
                  </button>
                </h2>
              </div>
              <div
                id="profileMenu"
                className="collapse"
                aria-labelledby="profileDraw"
                data-parent="#profileDrawer"
              >
                <div className="card-body p-0">
                  <ul className="nav flex-column list-unstyled">
                    <li className="nav-item">
                      <a href="#" data-toggle-theme="true" className="nav-link">
                        <i
                          className="fa font-12 color-yellow1-dark fa-lightbulb"
                          style={{ width: "16px" }}
                        />
                        <span>Dark Theme</span>
                        <i className="fa fa-circle" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link to="/Customize" className="nav-link">
                        <i className={"far fa-id-badge color-red1-dark"} />
                        <span>My Profile</span>
                        <i className="fa fa-circle" />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link id="nav-welcome" className="nav-link" to="/Contact">
                        <i
                          data-feather="users"
                          data-feather-line={1}
                          data-feather-size={16}
                          data-feather-color="indosat-merah-dark"
                          data-feather-bg="red2-fade-dark"
                        />
                        <span>Important Contact</span>
                        <i className="fa fa-circle" />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link id="nav-welcome" className="nav-link" to="/History">
                        <i
                          data-feather="rotate-ccw"
                          data-feather-line={1}
                          data-feather-size={16}
                          data-feather-color="indosat-merah-dark"
                          data-feather-bg="red2-fade-dark"
                        />
                        <span>History</span>
                        <i className="fa fa-circle" />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Apps" className="nav-link">
                        <i className={"fa fa-mobile-alt color-red1-dark"} />
                        <span>App</span>
                        <i className="fa fa-circle" />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#!"
                        className="nav-link"
                        onClick={this.handleLogout}
                      >
                        <i
                          data-feather="log-out"
                          data-feather-line={1}
                          data-feather-size={16}
                          data-feather-color="indosat-merah-dark"
                          data-feather-bg="red2-fade-dark"
                        />
                        <span>Log Out</span>
                        <i className="fa fa-circle" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Menu</span>
          </h6>
          <ul className="nav flex-column list-unstyled">
            <li className="nav-item">
              <NavLink to="/dashboard" className={`nav-link`}>
                <i
                  data-feather="home"
                  data-feather-line={2}
                  data-feather-size={21}
                  data-feather-color={
                    isActive == "dashboard" ? "red1-dark" : "gray1-dark"
                  }
                  data-feather-bg="gray1-fade-light"
                />
                <span>Home</span> <i className="fa fa-circle" />
              </NavLink>
            </li>
            <li className="nav-item" onClick={this.activeMenu("neorace")}>
              <NavLink to="/Neorace" className="nav-link">
                <i
                  data-feather="flag"
                  data-feather-line={2}
                  data-feather-size={21}
                  data-feather-color={
                    isActive == "Neorace" ? "red1-dark" : "gray1-dark"
                  }
                  data-feather-bg="gray1-fade-light"
                />
                <span>Neo Race</span> <i className="fa fa-circle" />
              </NavLink>
            </li>
            <li className="nav-item" onClick={this.activeMenu("classroom")}>
              <NavLink to="/Classroom" className="nav-link">
                <i
                  data-feather="book-open"
                  data-feather-line={2}
                  data-feather-size={21}
                  data-feather-color={
                    isActive == "Classroom" ? "red1-dark" : "gray1-dark"
                  }
                  data-feather-bg="gray1-fade-light"
                />
                <span>Classroom</span> <i className="fa fa-circle" />
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default withRouter(SideMenu);
