import React, { Component } from "react";
import {
  getProfil,
  getRecentActivities,
  getHistory,
  getMyBadges,
} from "../utils/Repository";
import ListHistory from "../component/ListHistory";
import { isMobile } from "react-device-detect";
import MyBadges from "../component/profile/MyBadges";
class AnotherProfile extends Component {
  state = {
    nama: "",
    propic: "",
    position_name: "",
    division: "",
    line_manager_name: "",
    buddy: "",
    twitter: "",
    linked: "",
    workplace: "",
    instagram: "",
    dataHistory: [],
    myBadges: [],
    badge: [],
  };

  getDataBadgeComponent(data) {
    this.setState({ badge: data });
  }

  getMyBadges() {
    return getMyBadges(this.props.match.params.nik)
      .then((resp) => resp.data)
      .then((data) => this.setState({ myBadges: data.result }))
      .catch((error) => console.log(error));
  }

  getApiProfile() {
    return getProfil(this.props.match.params.nik)
      .then((resp) => resp.data)
      .then((data) => {
        console.log(data.result[0]);
        this.setState({
          nama: data.result[0].personnel_number,
          propic: data.result[0].propic,
          division: data.result[0].division,
          position_name: data.result[0].position_name,
          line_manager_name: data.result[0].line_manager_name,
          buddy: data.result[0].buddy,
          twitter: data.result[0].twitter,
          linked: data.result[0].linked,
          workplace: data.result[0].workplace,
          instagram: data.result[0].instagram,
          nik: data.result[0].nik,
          regdate: data.result[0].regdate,
        });
      });
  }

  getActivity() {
    return getHistory(this.state.nik)
      .then((resp) => resp.data)
      .then((data) => data.result.content)
      .then((dataActivity) => this.setState({ dataHistory: dataActivity }));
  }

  componentDidMount() {
    this.getApiProfile();
    setTimeout(() => {
      this.getActivity();
      this.getMyBadges();
    }, 1500);
  }

  render() {
    const list = this.state.dataHistory.map((data, index) => (
      <ListHistory dataHistory={data} key={index} />
    ));

    const listmyBadges = this.state.myBadges.map((data, index) => {
      if (index < 5 && isMobile) {
        return (
          <MyBadges
            dataBadges={data}
            key={index}
            getData={this.getDataBadgeComponent.bind(this)}
          />
        );
      } else {
        return (
          <MyBadges
            dataBadges={data}
            key={index}
            getData={this.getDataBadgeComponent.bind(this)}
          />
        );
      }
    });
    return (
      <div>
        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
        </div>
        <div className="card card-style preload-img" data-card-height="55vh">
          <img src={this.state.propic} />
          <div className="card-bottom ml-3">
            <h1 className="font-40 line-height-xl">
              <br />
              {this.state.nama}
            </h1>
            <p className="pb-0 mb-0 font-12">
              <i className="fa fa-user mr-2 mb-4" />
              {this.state.position_name}
            </p>
          </div>
          <div className="card-overlay bg-gradient-fade" />
        </div>
        <div className="card card-style">
          <div className="content mb-0 mt-5">
            <div className="row mb-0 text-center">
              <div className="col-3">
                <a
                  href={
                    this.state.twitter == null || this.state.twitter == "null"
                      ? "https://twitter.com"
                      : this.state.twitter
                  }
                  className="icon icon-xs mr-1 rounded-xl bg-red1-dark"
                >
                  <i className="fab fa-twitter" />
                </a>
                <p className="font-10 mb-0 pb-0">Twitter</p>
              </div>
              <div className="col-3">
                <a
                  href={
                    this.state.instagram == null ||
                    this.state.instagram == "null"
                      ? "https://www.instagram.com/?hl=id"
                      : this.state.instagram
                  }
                  className="icon icon-xs mr-1 rounded-xl bg-red1-dark"
                >
                  <i className="fab fa-instagram" />
                </a>
                <p className="font-10 mb-0 pb-0">Instagram</p>
              </div>
              <div className="col-3">
                <a
                  href={
                    this.state.linked == null || this.state.linked == "null"
                      ? "https://www.linkedin.com/"
                      : this.state.linked
                  }
                  className="icon icon-xs mr-1 rounded-xl bg-red1-dark"
                >
                  <i className="fab fa-linkedin-in" />
                </a>
                <p className="font-10 mb-0 pb-0">Linked In</p>
              </div>
              <div className="col-3">
                <a
                  href={
                    this.state.workplace == null ||
                    this.state.workplace == "null"
                      ? "https://work.workplace.com/"
                      : this.state.workplace
                  }
                  className="icon icon-xs mr-1 rounded-xl bg-red1-dark"
                >
                  <img
                    src={"/images/icons/workplace-white.png"}
                    className="rounded-circle shadow-l"
                    style={{ margin: "5px" }}
                    width={25}
                    height={25}
                    alt="description of image1"
                  />
                </a>
                <p className="font-10 mb-0 pb-0">Workplace</p>
              </div>
            </div>
            <div className="divider mb-4 mt-3" />
            {isMobile ? (
              <div
                className="content"
                style={{ height: "100px", overflowY: "auto" }}
              >
                <h5>
                  <small>Bagdes</small>
                </h5>
                <div className="user-list-slider owl-carousel mt-0 mb-n1">
                  {listmyBadges}
                </div>
              </div>
            ) : (
              <div
                className="content user-list-badge"
                style={{ height: "310px", overflowY: "auto" }}
              >
                <h2>
                  <small>Bagdes</small>
                </h2>
                {listmyBadges}
              </div>
            )}
            <div className="divider mb-4 mt-3" />
            {list.length != 0 ? (
              list
            ) : (
              <p className="text-center mb-3">No Activity</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default AnotherProfile;
