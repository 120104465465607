import React, { Component } from "react";
import $ from "jquery";
import Axios from "axios";
import ReactPlayer from "react-player";
import {
  getCeo,
  getUser,
  setCeo,
  getButtonSurvey,
  buttonSurvey,
} from "../utils/Common";
import { getVideoCeo } from "../utils/Repository";
class ModalCeo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlVideoCeo: "",
      show: true,
      lastduration: "",
      duration: null,
      secondsElapsed: null,
      disable: true,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }
  getVideoCeo = () => {
    return getVideoCeo().then((respone) => {
      if (respone.data.result.last_duration == null) {
        this.setState({ urlVideoCeo: respone.data.result.videoUrl });
      } else {
        var last = `${respone.data.result.videoUrl}?t=${respone.data.result.last_duration}`;
        this.setState({ urlVideoCeo: last });
      }
    });
  };

  Survey() {
    this.setState({ disable: false });
    buttonSurvey(false);
    console.log(getButtonSurvey());
  }
  onDuration = (duration) => {
    this.setState({ duration });
  };
  onProgress = (progress) => {
    if (!this.state.duration) {
      return;
    }
    const secondsElapsed = progress.played * this.state.duration;
    if (secondsElapsed !== this.state.secondsElapsed) {
      this.setState({ secondsElapsed });
    }

    if (secondsElapsed == this.state.duration) {
      let form = new FormData();
      form.append("user_id", getUser()[0].nik);
      Axios.post(
        `https://neo.deprakoso.com/content/watchedOpening`,
        form
      );
    }
  };

  onPause = () => {
    Axios.get(
      `https://neo.deprakoso.com/content/updateCeoAccess?nik=${
        getUser()[0].nik
      }&duration=${this.state.secondsElapsed}`
    );
  };
  handleClose() {
    this.setState({ show: false });
    document.body.classList.remove("modal-open");
    document.getElementById("exampleModal").classList.remove("show");
    setCeo(true);
    console.log(getCeo());
    this.onPause();
  }

  handleShow() {
    this.setState({ show: true });
  }
  componentDidMount() {
    this.getVideoCeo();
    buttonSurvey(true);
    console.log(getButtonSurvey());
  }

  render() {
    return (
      <div>
        {this.state.show ? (
          <div className={"modal-backdrop fade show"}></div>
        ) : (
          ""
        )}

        <div
          className={"modal fade" + (this.state.show ? " show d-block" : "")}
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Opening Speech</h5>
              </div>
              <div className="modal-body">
                <ReactPlayer
                  width="auto"
                  height="300px"
                  playing={this.state.show ? true : false}
                  url={this.state.urlVideoCeo}
                  onDuration={this.onDuration}
                  onProgress={this.onProgress}
                  onPause={this.onPause}
                  controls={true}
                />
                {/* <a
                  href="https://o365indosat.sharepoint.com/:x:/s/HRDevelopment/EUaJwqmQR7JIrek5fCqMAVUBC8YhRWlK0LsZH3a16oQV-A?e=5n2ouD"
                  target="_blank"
                  className={
                    "btn btn-m mt-4 rounded-xl bg-indosat-merah-dark btn-full  rounded-sm text-uppercase font-900"
                  }
                  onClick={() => this.Survey()}
                >
                  Fill Survey Form
                </a> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.handleClose}
                  // disabled={
                  //   getButtonSurvey() && this.state.disable ? true : false
                  // }
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalCeo;
