import React, { Component } from "react";
import Menu from "./Menu";
import $ from "jquery";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <div>
        <div
          id="menu-main"
          className="menu menu-box-right menu-box-detached rounded-m"
          data-menu-width={260}
          data-menu-active="nav-pages"
          data-menu-effect="menu-over"
        >
          <Menu />
        </div>
      </div>
    );
  }
}

export default Sidebar;
