import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import CardModule from "./component/CardModule";
import Axios from "axios";
import queryString from "query-string";
import { getUser } from "../../utils/Common";
import ModalConfirm from "../ModalConfirm";
import ModalVideo from "../ModalVideo";
import InfiniteScroll from "react-infinite-scroll-component";
import { getReadingMaterial, getVideoNew } from "../../utils/Repository";
class ViewGridExplore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pdf: false,
      cat: "",
      url: "",
      lastDuration: "",
      resumeVideo: "",
      resestVideo: "",
      fromRow: "0",
      list: null,
    };
  }
  fetchData = () => {
    // getdata menggunakan axios
    let q = queryString.parse(this.props.location.search);
    if (q.cat == "reading_material") {
      this.setState({ pdf: true });
      return getReadingMaterial(this.state.fromRow)
        .then((resp) => resp.data)
        .then((data) => data.result.videos)
        .then((dataPDF) => this.setState({ data: dataPDF, fromRow: "10" }));
    } else {
      this.setState({ cat: q.cat });
      return getVideoNew(q.cat, 0)
        .then((resp) => resp.data)
        .then((data) => data.result.videos)
        .then((datavideos) =>
          // console.log(datavideos)
          this.setState({ data: datavideos, fromRow: "10" })
        );
    }
  };

  scroll = () => {
    let q = queryString.parse(this.props.location.search);
    if (q.cat == "reading_material") {
      this.setState({ pdf: true });
      return getReadingMaterial(this.state.fromRow)
        .then((resp) => resp.data)
        .then((data) => data.result.videos)
        .then((dataPdf) => {
          for (let index = 0; index < dataPdf.length; index++) {
            this.setState({ data: this.state.data.concat(dataPdf[index]) });
          }
          this.setState({ fromRow: this.state.fromRow + 10 });
        });
    } else {
      this.setState({ cat: q.cat });
      return getVideoNew(q.cat, this.state.fromRow)
        .then((resp) => resp.data)
        .then((data) => data.result.videos)
        .then((datavideos) => {
          for (let index = 0; index < datavideos.length; index++) {
            this.setState({ data: this.state.data.concat(datavideos[index]) });
          }
          this.setState({ fromRow: this.state.fromRow + 10 });
        });
    }
  };

  componentDidUpdate() {}

  getUrlVideo(data) {
    setTimeout(
      function () {
        //Start the timer
        var getUrl = data.url.split("/");
        this.setState({
          lastDuration: data.lastDuration,
          resumeVideo:
            "/playvideo?cat=" +
            this.state.cat +
            "&vid=" +
            getUrl[3] +
            "&duration=" +
            data.lastDuration +
            "&content_id=" +
            data.id,

          resestVideo:
            "/playvideo?cat=" +
            this.state.cat +
            "&vid=" +
            getUrl[3] +
            "&duration=0&content_id=" +
            data.id,
        });
      }.bind(this),
      100
    );
  }
  componentDidMount() {
    // fungsi ini di jalankan ketika pertama kali di load
    this.fetchData();
  }
  render() {
    // .sort((a, b) => (a.completionState == b.completionState ? 1 : -1))
    const list = this.state.data.map((data, index) => (
      <CardModule
        dataJSON={data}
        key={index}
        pdf={this.state.pdf}
        category={this.state.cat}
        getUrlVideo={this.getUrlVideo.bind(this)}
      />
    ));
    return (
      <div>
        {/* <div className="video-content"> */}
        <InfiniteScroll
          className="video-content"
          dataLength={this.state.data.length}
          next={this.scroll}
          inverse={true} //
          hasMore={true}
        >
          {list}
        </InfiniteScroll>
        {/* </div> */}

        <ModalConfirm
          ceklastDuration={this.state.lastDuration == null ? false : true}
          resetVideo={this.state.resestVideo}
          resumeVideo={this.state.resumeVideo}
        />
      </div>
    );
  }
}

export default withRouter(ViewGridExplore);
