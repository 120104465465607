import {
  getUser,
  setUser,
  setTokenFcm,
  getToken,
  removeUserSession,
  setToken,
} from "./Common";
import Axios from "axios";
import firebase from "./firebase";
import http from "http";
import https from "https";

const httpAgent = new http.Agent({ keepAlive: true });
const httpsAgent = new https.Agent({ keepAlive: false });
// let url = "http://localhost/indosat-neo";
// let url = "http://localhost:8000/api";
// let url = "http://51.79.230.10:2087/indosat-neo";
// let url = "https://neo.deprakoso.com";
// let url = "http://10.34.87.199:83/api";
// let url = "https://neo.indosatooredoo.com/neoapi/api";
let url = process.env.REACT_APP_API_URL;
let headers = {
  headers: {
    Authorization: getToken() && "Bearer " + getToken(),
  },
};

export const getProfileUser = (username) => {
  let form = new FormData();
  form.append("nik", username);
  Axios.post(url + `/Apiuser/getProfile`, form).then((response) => {
    if (response.status == 200) {
      setUser(response.data.result);
      window.location.reload();
    }
  });
};

export const getProfil = (username) => {
  let form = new FormData();
  form.append("nik", username);
  let api = Axios.post(url + `/Apiuser/getProfile`, form);
  return api;
};

export const getAuth = () => {
  // console.log(process.env.REACT_APP_API_URL)
  if (getToken()) {
    let form = new FormData();
    let api = Axios.post(url + `/Apiuser/getAuth`, form, headers)
      .then((response) => {
        if (response.status == 200) {
          setUser(response.data.result);
          if (response.data.result[0].token) {
            setToken(response.data.result[0].token);
          }
        }
      })
      .catch((e) => {
        if (e.response.status == 401) {
          removeUserSession();
          return window.location.assign("/");
        }
      });
    return api;
  } else {
    removeUserSession();
    // console.log("token not found")
  }
};

export const getLogin = async (form) => {
  let api = Axios
    .post(url+`/Apiuser/loginAPI`, form
    // ,{
    //   proxy: {
    //       host: '10.10.100.250',
    //       port: 8080,
    //       protocol: 'http'
    //   },
    // }
  ).catch(err => {
    console.log(err)
    // res.send({ err }) // <= send error
  });
  return api;
};

export const getProfileUserLogin = (username) => {
  let form = new FormData();
  form.append("nik", username);
  Axios.post(url + `/Apiuser/getProfile`, form).then((response) => {
    if (response.status == 200) {
      setUser(response.data.result);
      window.location.reload();
      window.location.assign("/dashboard");
      // window.location.href = "/dashboard";
    }
  });
};

export const getFecthStage = (username) => {
  let form = new FormData();
  form.append("nik", username);
  let request = Axios.post(url + `/ApiContent/getStages`, form, headers);

  return request;
};

export const getMission = (nik, raceid, stage) => {
  let form = new FormData();
  form.append("nik", nik);
  form.append("race_id", raceid);
  form.append("stage", stage);
  let request = Axios.post(url + `/ApiContent/getMissions`, form, headers);
  return request;
};

export const getQuest = (nik, missionID) => {
  let form = new FormData();
  form.append("nik", nik);
  form.append("mission_id", missionID);
  let request = Axios.post(url + `/ApiContent/getQuest`, form, headers);
  return request;
};

export const getBadges = () => {
  let request = Axios.get(url + `/ApiContent/getBadges`);
  return request;
};

export const getMyBadges = (nik) => {
  let form = new FormData();
  form.append("nik", nik);
  let request = Axios.post(url + `/ApiContent/getMyBadges`, form, headers);
  return request;
};

export const updateProfile = (data) => {
  console.log(data);
  let request = Axios.post(url + `/Apiuser/updateProfile`, data, headers);
  return request;
};
export const updatePicture = (data) => {
  let request = Axios.post(url + `/Apiuser/updatePropic`, data, headers);

  return request;
};
export const getHistoryMerge = () => {
  let request = Axios.get(
    url + `/ApiContent/historyListMerged?nik=${getUser()[0].nik}`,
    headers
  );
  return request;
};

export const getHistory = (nik) => {
  let request = Axios.get(
    url + `/ApiContent/historyListMerged?nik=${nik}`,
    headers
  );
  return request;
};

export const StartMission = (data) => {
  let request = Axios.post(url + `/ApiContent/startMission`, data, headers);

  return request;
};
export const SubmitMission = async (data, missionid) => {
  let form = new FormData();
  form.append("nik", getUser()[0].nik);
  form.append("missionId", missionid);
  form.append("dataQuest", JSON.stringify(data));

  let request = Axios.post(url + `/ApiContent/submitQuest`, form, headers);

  return request;
};

export const generateToken = () => {
  // if (firebase.messaging.isSupported()) {
  //   const messaging = firebase.messaging()
  //   messaging.requestPermission().then(() => {
  //     return messaging.getToken()
  //   }).then(token => {
  //     setTokenFcm(token)
  //     console.log(token)
  //   }).catch((error) => { console.log(error) })
  // }
};

export const getVideoExplore = (cat) => {
  let request = Axios.get(
    url + `/ApiContent/videoNew/${cat}?nik=${getUser()[0].nik}`,
    headers
  );
  return request;
};
export const getPlaybook = (cat) => {
  let request = Axios.get(url + `/ApiContent/playbook`, headers);

  return request;
};

export const getContentCompletion = (data) => {
  let request = Axios.post(
    url + `/ApiContent/contentCompletion`,
    data,
    headers
  );

  return request;
};

export const getRecentActivities = (data) => {
  let request = Axios.post(url + `/ApiContent/recentactivity`, data, headers);

  return request;
};

export const getVideoCeo = () => {
  let request = Axios.get(
    url + `/ApiContent/videoceo?nik=${getUser()[0].nik}`,
    headers
  );

  return request;
};

export const getContactBatch = (data) => {
  let request = Axios.post(url + `/Apiuser/getContactBatch`, data, headers);
  return request;
};

export const getReadingMaterial = (fromRow) => {
  let request = Axios.get(
    url +
      `/ApiContent/videoNew/reading_material?nik=${
        getUser()[0].nik
      }&fromrow=${fromRow}`,
    headers
  );
  return request;
};

export const getVideoNew = (cat, from) => {
  if (from == null) {
    let request = Axios.get(
      url +
        `/ApiContent/videoNew/${cat}?nik=${
          getUser()[0].nik
        }&fromrow=0&limitrow=10`,
      headers
    );
    return request;
  } else {
    let request = Axios.get(
      url +
        `/ApiContent/videoNew/${cat}?nik=${getUser()[0].nik}&fromrow=${from}`,
      headers
    );
    return request;
  }
};

export const getContactStatic = () => {
  let request = Axios.get(url + `/Apiuser/getStaticContact`);
  return request;
};

export const getContact = () => {
  let request = Axios.get(
    url + `/Apiuser/getStaticContact?nik=${getUser()[0].nik}`,
    headers
  );
  return request;
};

export const watchOpening = (form) => {
  let request = Axios.post(url + `/ApiContent/watchedOpening`, form);

  return request;
};

export const updateCeo = (seccond) => {
  let request = Axios.get(
    url +
      `/ApiContent/updateCeoAccess?nik=${getUser()[0].nik}&duration=${seccond}`
  );
  return request;
};

export const videoLastAccess = (form) => {
  let request = Axios.post(url + `/ApiContent/videoLastAccess`, form, headers);
  return request;
};

export const getChat = (id, from, limit) => {
  let form = new FormData();
  form.append("batchid", id);
  form.append("fromrow", from);
  form.append("limitrow", limit);
  let request = Axios.post(url + `/ApiContent/getChat`, form);
  return request;
};
export const getBatchChat = () => {
  // form.append("batchid", id);
  // form.append("fromrow", from);
  // form.append("limitrow", limit);
  let request = Axios.get(url + `/ApiContent/getBatchLists`);
  return request;
};
export const saveChat = (id, msg, type, nik) => {
  let form = new FormData();
  form.append("batchid", id);
  form.append("msg", msg);
  form.append("type", type);
  form.append("nik", nik);
  let request = Axios.post(url + `/ApiContent/saveChat`, form);
  return request;
};

export const getKota = () => {
  let request = Axios.get(url + `/utilities/getListKotKab`);
  return request;
};
