import Axios from "axios";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { getUser } from "../../utils/Common";
import { getFecthStage } from "../../utils/Repository";
import CardModule from "../classroom/component/CardModule";
import CardStage from "./Component/CardStage";
import Slider from "react-slick";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { dataStage: [], dataCompletion: [] };
  }
  getApi = () => {
    return getFecthStage(getUser()[0].nik)
      .then((resp) => resp.data)
      .then((data) => {
        data.result && data.result.map((data, index) => {
          if (
            data.is_complete == "TRUE" ||
            data.is_complete == "true" ||
            data.is_complete == true
          ) {
            this.state.dataCompletion.push(true);
          } else {
            this.state.dataCompletion.push(false);
          }
        });
        this.setState({ dataStage: data.result });
      })
      .catch(console.error());
  };
  componentDidMount() {
    this.getApi();
    var owl = window.$("#sliderr");
  }
  render() {
    const countStage = this?.state?.dataStage?.length || 0;
    const listStage = this.state.dataStage && this.state.dataStage.map((data, index) => (
      <CardStage
        dataJSON={data}
        key={index}
        no={index == 0 ? 0 : index - 1}
        dataCompletion={this.state.dataCompletion}
        size={countStage}
      />
    ));

    return (
      <div>
        <div className="cover-slider owl-carousel mb-0" id={"sliderr"}>
          {listStage}
        </div>

        <div
          id="toast-4"
          className="toast toast-tiny toast-bottom bg-red2-dark"
          data-delay={3000}
          data-autohide="true"
          style={{ width: "80%", textAlign: "center" }}
        >
          Please complete previous stage to unlock
        </div>
      </div>
    );
  }
}

export default withRouter(Index);
