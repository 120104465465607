import React, { useState, useEffect, useRef } from "react";
import { getRole, getUser } from "../utils/Common";
import Messages from "../component/classroom/component/Messages/Messages";
import moment from "moment";
import { getChat, saveChat } from "../utils/Repository";
import { Redirect } from "react-router-dom";
import SideMenu from "../component/SideMenu";
import socketIOClient from "socket.io-client";
import InfiniteScroll from "react-infinite-scroll-component";
import firebase from "../utils/firebase";
let socket;
const Chatroom = (props) => {
  const messagesEndRef = useRef(null);
  const scrollRef = useRef(null);
  const ENDPOINT = "https://neo.deprakoso.com";
  const chatContainer = useRef(null);
  let batchid = props.match.params.batchid;
  let background = "/images/pictures/bgchat.png";
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [from, setfrom] = useState("");
  const [fromMessage, setfromMessage] = useState("");
  const [dataChat, setData] = useState([]);
  const [count, setCount] = useState();
  const [picture, setPicture] = useState("");
  const [typeFile, setType] = useState("");
  const [row, setRow] = useState(0);
  const role = getRole();
  useEffect(
    () => {
      if (firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();
        messaging.onMessage((payload) => {
          console.log("Message received. ", payload);
        });
      }
      function scrollTop() {
        window.addEventListener("scroll", scroll);
      }
      scrollTop();
      if (role === "admin" || batchid === getUser()[0].batch_id) {
        const { name, room } = { name: getUser()[0].nama, room: batchid };
        socket = socketIOClient(ENDPOINT);
        setName(name);
        setRoom(batchid);
        chatdata();
        socket.emit("join", { name, room }, () => {});
        return () => {
          socket.emit("disconnect");
          socket.off();
        };
      } else {
        return <Redirect to={`/chatroom/${getUser()[0].batch_id}`} />;
      }
    },
    [ENDPOINT],
    getUser()
  );
  function scroll() {
    if (window.pageYOffset == 0) {
      loadmoreChat();
    }
  }
  const chatdata = async () => {
    const response = getChat(batchid, row, 9);
    setData((await response).data.result);
    setCount(0);
    // scrollToBottom();
  };

  const loadmoreChat = () => {
    var fromrow = row + 10;
    setRow(fromrow);

    return getChat(batchid, fromrow, 9)
      .then((res) => res.data)
      .then((data) => data.result)
      .then((result) =>
        result.map((data, index) => {
          setData((dataChat) => [...dataChat, data]);
        })
      );
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const clickimage = (e) => {
    e.preventDefault();
    document.getElementById("uploadimage").click();
  };

  useEffect(() => {
    if (role === "admin" || batchid === getUser()[0].batch_id) {
      socket.on("message", ({ msg, personnel_number, timecreated, type }) => {
        let obj = {
          ext: "",
          msg: msg,
          personnel_number: personnel_number,
          timecreated: timecreated,
          type: type,
        };
        if (personnel_number != getUser()[0].nama) {
          setfromMessage(msg);
          setfrom(personnel_number);
          window.$("#notification-4").toast("show");
        }
        setData([...dataChat, obj]);
        // scrollToBottom();
      });
    } else {
      window.location.href = `/chatroom/${getUser()[0].batch_id}`;
    }
  }, [dataChat]);

  const sendMessage = (e) => {
    e.preventDefault();
    var timecreated = moment().format("X");
    let nik = getUser()[0].nik;
    let personnel_number = getUser()[0].nama;
    let type = "";
    if (typeFile === "application/pdf") {
      type = "pdf";
    } else if (
      typeFile === "image/png" ||
      typeFile === "image/jpeg" ||
      typeFile === "image/jpg"
    ) {
      type = "file";
    } else {
      type = "text";
    }
    let bacthid = batchid;
    var sendMessage = saveChat(bacthid, message, type, nik);
    if (message) {
      sendMessage
        .then((resp) => {
          if (resp.data === true) {
            if (type === "file" || type === "pdf") {
              window.$("#menu-video").hideMenu();
            }
            socket.emit(
              "sendMessage",
              { personnel_number, message, timecreated, type, nik },
              () => {
                setMessage("");
                setPicture("");
                setType("");
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const uploadImage = (e, cb) => {
    if (e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setPicture(reader.result);
        cb(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div>
      <SideMenu />

      <div
        ref={scrollRef}
        className="page-content"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "contain",
          overflow: "scroll",
        }}
      >
        <div className="header header-logo-app">
          <a
            href={"#/"}
            data-back-button
            className="header-title header-subtitle"
          >
            Back
          </a>
          <a href={"#/"} data-back-button className="header-icon header-icon-1">
            <i className="fas fa-arrow-left" />
          </a>
        </div>
        <div id="footer-bar" className="d-flex">
          <div className="mr-3 speach-icon">
            <a
              href="#!"
              onClick={(e) => clickimage(e)}
              // data-menu="menu-upload"
              className="bg-gray2-dark ml-2"
            >
              <i className="fa fa-plus" />
            </a>
            <input
              type="file"
              id={`uploadimage`}
              hidden
              accept=".pdf,.jpg,.jpeg,.png"
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                let Basee64 = "";
                if (e.target.files[0].size > 207200) {
                  window.$("#toast-4").toast("show");
                } else {
                  console.log(e.target.files[0]);
                  setType(e.target.files[0].type);
                  window.$("#menu-video").showMenu();
                  window.$("#menu-video").appendTo("body");
                  uploadImage(e, (result) => {
                    Basee64 = result;
                    setMessage(Basee64);
                  });
                }
              }}
            />
          </div>
          <div className="flex-fill speach-input">
            <form className="form" style={{ display: "flex" }}>
              <input
                className="form-control"
                type="text"
                placeholder="Type a message..."
                value={message}
                onChange={({ target: { value } }) => setMessage(value)}
                onKeyPress={(event) =>
                  event.key === "Enter" ? sendMessage(event) : console.log("")
                }
              />
            </form>
          </div>
          <div className="ml-3 speach-icon" onClick={(e) => sendMessage(e)}>
            <a href={"#/"} className="bg-red1-dark mr-2">
              <i className="fa fa-arrow-right" />
            </a>
          </div>
        </div>
        <div
          id="scrolTop"
          className="content mt-5 pt-3"
          onScroll={() => console.log("test")}
        >
          <Messages
            messages={dataChat.sort(function (a, b) {
              return a.timecreated - b.timecreated;
            })}
            name={name}
          />
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div
        id="toast-4"
        className="toast toast-tiny toast-bottom bg-red2-dark mb-3"
        data-delay={3000}
        data-autohide="true"
        style={{ width: "80%", textAlign: "center" }}
      >
        The file is too large, maximum 2MB
      </div>
      <div
        id="menu-video"
        className="menu menu-box-modal rounded-m"
        data-menu-height="390"
        data-menu-width="330"
      >
        <div className="file-data">
          {typeFile === "application/pdf" ? (
            ""
          ) : (
            <img
              className="img-fluid rounded-xs mt-4"
              src={picture}
              style={{ maxHeight: "200px", width: "max-content" }}
            />
          )}
        </div>
        <h3 className="text-center font-700 mt-3">
          are you sure you want to send this file ?
        </h3>
        <p className="boxed-text-l"></p>
        <div className="row mr-3 ml-3 mb-0">
          <div className="col-6">
            <a
              href="#"
              className="close-menu btn btn-sm btn-full button-s shadow-l rounded-s text-uppercase font-900 bg-red1-dark"
            >
              CANCEL
            </a>
          </div>
          <div className="col-6">
            <a
              href="#!"
              onClick={(e) => sendMessage(e)}
              className="btn btn-sm btn-full button-s shadow-l rounded-s text-uppercase font-900 bg-green1-dark"
            >
              SEND
            </a>
          </div>
        </div>
      </div>
      <div
        id="notification-4"
        data-dismiss="notification-4"
        data-delay={3000}
        data-autohide="true"
        className="notification bg-theme shadow-xl"
      >
        <div className="toast-header">
          <strong className="mr-auto">{from}</strong>
          <small className="text-muted">just now</small>
          <button
            type="button"
            className="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="toast-body">{fromMessage}</div>
      </div>
    </div>
  );
};
export default Chatroom;
