import Axios from "axios";
import React, { Component } from "react";
import { getUser } from "../../utils/Common";
import Contact from "../profile/Contact";
import ListLeaderBoard from "./component/ListLeaderBoard";
import { getContactBatch } from "../../utils/Repository";
class LeaderBoard extends Component {
  constructor(props) {
    super(props);
    this.state = { dataContact: [] };
  }
  fetch = () => {
    let data = new FormData();
    data.append("nik", getUser()[0].nik);
    return getContactBatch(data)
      .then((resp) => resp.data)
      .then((data) => data.result)
      .then((dataCon) => this.setState({ dataContact: dataCon }));
  };

  componentDidMount() {
    this.fetch();
  }
  render() {
    const listContact = this.state.dataContact.map((data, index) => (
      <ListLeaderBoard dataContact={data} key={index} />
    ));
    return (
      <div
        className="tab-content pb-5"
        id="tab-1"
        style={{ height: "80%", overflowY: "scroll" }}
      >
        <div className="accordion" id="accordion-1">
          {listContact}
        </div>
      </div>
    );
  }
}

export default LeaderBoard;
