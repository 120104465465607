import React, { Component } from "react";
import { getUser } from "../utils/Common";

class BadgePicture extends Component {
  constructor(props) {
    super(props);
    this.state = { datUser: null ,image:null};
  }
  render() {
    return (
      <div>
        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src={this.props.image != null ? this.props.image : "/images/avatars/5s.png"}
          />
        </div>
        <div className="card card-style">
          <div className="content">
            <div className="d-flex">
              <div>
                <img
                  src={getUser()[0].propic != null ? getUser()[0].propic : "/images/avatars/5s.png"}
                  width={50}
                  height={50}
                  className="mr-3 bg-highlight rounded-xl"
                  alt={"gambar"}
                />
              </div>
              <div>
                <h1 className="mb-0 pt-1">
                  {this.props.dataUser[0].nama}
                </h1>
                <p className="color-highlight font-11 mt-n2 mb-3">
                  {this.props.dataUser[0].jabatan}
                </p>
              </div>
            </div>
            <p>Hallo, what do you want to do today?</p>
          </div>
        </div>
      </div>
    );
  }
}

export default BadgePicture;
