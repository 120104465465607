import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getUser } from "../../utils/Common";
import { isIOS, isMobile, isBrowser } from "react-device-detect";
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  showListShare(type) {
    window.$("#menu-share-list").showMenu();
    window.$("#menu-share-list").appendTo("body");
    this.props.getData(this.props.dataContact);
    this.props.type(type);
  }
  render() {
    return (
      <div
        className="mb-0"
        style={
          getUser()[0].nik == this.props.dataContact.username
            ? { display: "none" }
            : { display: "block" }
        }
      >
        <button
          className="btn pl-0 pr-0 accordion-btn"
          data-toggle="collapse"
          data-target={`#collapse${this.props.dataContact.id}`}
          aria-expanded="false"
        >
          <div className="user-left">
            <div className="d-flex">
              <div>
                <img
                  src={"images/avatars/avatar kunning.png"}
                  className="mr-3 rounded-circle shadow-l"
                  width={40}
                  height={40}
                  alt="description of image1"
                />
              </div>
              <div>
                <h5 className="mt-1 mb-0 color-red1-dark">
                  {this.props.dataContact.name}
                </h5>
                <p className="font-10 mt-n1 color-gray2-dark">
                  {this.props.dataContact.title}
                </p>
              </div>
              <div className="ml-auto">
                <i className="fas fa-bars"></i>
              </div>
            </div>
          </div>
        </button>
        <div
          id={`collapse${this.props.dataContact.id}`}
          className="collapse"
          data-parent="#accordion-1"
        >
          <div className="pt-1 pb-2">
            <p></p>
            <div className="text-center">
              Phone : {this.props.dataContact.phone}
            </div>
            <div className="text-center">
              Email :{" "}
              {this.props.dataContact.email == null
                ? "-"
                : this.props.dataContact.email}
            </div>
            <p />
            <div className="text-center mb-3">
              {isMobile ? (
                <a
                  style={{ margin: "5px" }}
                  // href={`https://teams.microsoft.com/l/call/0/0?users=${this.props.dataContact.email}`}
                  onClick={() => this.showListShare("phone")}
                  className="icon icon-xs rounded-circle shadow-l bg-indosat-merah-dark"
                  target="_blank"
                  data-menu="menu-call"
                >
                  <i className="fa fa-phone" />
                </a>
              ) : isBrowser ||
                this.props.dataContact.phone != "-" ||
                this.props.dataContact.phone != null ||
                this.props.dataContact.email != null ? (
                <a
                  style={{ margin: "5px" }}
                  href={`https://teams.microsoft.com/l/call/0/0?users=${this.props.dataContact.email}`}
                  className="icon icon-xs rounded-circle shadow-l bg-indosat-merah-dark"
                  target="_blank"
                  data-menu="menu-call"
                >
                  <i className="fa fa-phone" />
                </a>
              ) : (
                <div
                  style={{ margin: "5px" }}
                  className="icon icon-xs rounded-circle shadow-l bg-gray2-dark"
                  data-menu="menu-call"
                >
                  <i className="fa fa-phone" />
                </div>
              )}

              {isMobile ? (
                <a
                  style={{ margin: "5px" }}
                  // href={`https://teams.microsoft.com/l/call/0/0?users=${this.props.dataContact.email}`}
                  onClick={() => this.showListShare("message")}
                  className="icon icon-xs rounded-circle shadow-l bg-indosat-merah-dark"
                  target="_blank"
                  data-menu="menu-call"
                >
                  <i className="fa fa-comment" />
                </a>
              ) : isBrowser ||
                this.props.dataContact.phone != "-" ||
                this.props.dataContact.phone != null ||
                this.props.dataContact.email != null ? (
                <a
                  style={{ margin: "5px" }}
                  href={`https://teams.microsoft.com/l/chat/0/0?users=${this.props.dataContact.email}`}
                  className="icon icon-xs rounded-circle shadow-l bg-indosat-merah-dark"
                  target="_blank"
                  data-menu="menu-call"
                >
                  <i className="fa fa-comment" />
                </a>
              ) : (
                <div
                  style={{ margin: "5px" }}
                  className="icon icon-xs rounded-circle shadow-l bg-gray2-dark"
                  data-menu="menu-call"
                >
                  <i className="fa fa-comment" />
                </div>
              )}

              {this.props.dataContact.phone == "-" ||
              this.props.dataContact.phone == null ? (
                <div
                  style={{ margin: "5px" }}
                  className="icon icon-xs rounded-circle shadow-l bg-gray2-dark"
                >
                  <i className="fab fa-whatsapp"></i>
                </div>
              ) : (
                <a
                  style={{ margin: "5px" }}
                  href={`https://api.whatsapp.com/send?phone=${this.props.phone}&text=Hello`}
                  className="icon icon-xs rounded-circle shadow-l bg-indosat-merah-dark"
                >
                  <i className="fab fa-whatsapp"></i>
                </a>
              )}
              {this.props.dataContact.email == "-" ||
              this.props.dataContact.email == null ? (
                <div
                  style={{ margin: "5px" }}
                  className="icon icon-xs rounded-circle shadow-l bg-gray2-dark"
                >
                  <i className="fas fa-at"></i>
                </div>
              ) : (
                <a
                  style={{ margin: "5px" }}
                  href={`mailto:${this.props.dataContact.email}`}
                  className="icon icon-xs rounded-circle shadow-l bg-indosat-merah-dark"
                >
                  <i className="fas fa-at" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Contact);
