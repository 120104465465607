import React, { Component } from "react";
import queryString from "query-string";
import { getMission, StartMission } from "../../utils/Repository";
import { getUser } from "../../utils/Common";
import { Link } from "react-router-dom";
import $ from "jquery";
class Mission extends Component {
  constructor(props) {
    super(props);
    this.state = { dataMission: [], raceId: "", stage: "", id: "" };
  }
  getApi = () => {
    let q = queryString.parse(this.props.location.search);
    this.setState({ raceId: q.raceId, stage: q.stage, id: q.id });
    return getMission(getUser()[0].nik, q.raceId, q.stage)
      .then((resp) => resp.data)
      .then((data) =>
        data.result
          .filter((x) => x.id == this.state.id)
          .map((x) => this.setState({ dataMission: x }))
      )
      .catch((error) => console.log(error));
  };
  startMission = () => {
    let form = new FormData();
    form.append("nik", getUser()[0].nik);
    form.append("mission_id", this.state.dataMission.id);
    return StartMission(form).then((result) => {
      console.log(result);
      if (
        result.data.message == "User already start the missions" &&
        result.data.error == false
      ) {
        window.location.assign(
          `/Neorace/checkpoint?missionid=${this.state.dataMission.id}&raceid=${this.state.raceId}&stage=${this.state.stage}`
        );
      } else {
        window.$("#toast-4").toast("show");
      }
    });
  };
  componentDidMount() {
    this.getApi();
  }

  render() {
    return (
      <div>
        <div
          id="toast-4"
          className="toast toast-tiny toast-bottom bg-red2-dark"
          data-delay={3000}
          data-autohide="true"
        >
          <i className="fa fa-times mr-3" />
          Opps,Failed
        </div>
        <div className="card card-style">
          <div className="content">
            <h3 className="text-center">Mission </h3>
            <div className="text-center">
              <i className="fas fa-people-arrows font-30 pt-3 color-red1-dark fa-8x" />
            </div>
            <p></p>
            <h5>Your Mission</h5>
            {this.state.dataMission.name}
            <p />
            <h5>Description</h5>
            <p>{this.state.dataMission.description}</p>
            <div className="row mb-0">
              <div className="col-6">
                <Link
                  to={`/Neorace/stage?raceId=${this.state.raceId}&stage=${this.state.stage}`}
                  className="simulate-android-banner btn btn-m btn-full rounded-s shadow-xl text-uppercase font-900 bg-indosat-kuning-dark"
                >
                  Close
                </Link>
              </div>
              {this.state.dataMission.is_complete.includes("true") ? (
                <div className="col-6">
                  <div
                    className={
                      "btn btn-m btn-full mb-3 rounded-xs text-uppercase font-900 shadow-s bg-green1-dark"
                    }
                  >
                    Completed
                  </div>
                </div>
              ) : (
                <div className="col-6">
                  <div
                    onClick={this.startMission}
                    className={
                      "btn btn-m btn-full mb-3 rounded-xs text-uppercase font-900 shadow-s bg-indosat-merah-dark"
                    }
                  >
                    Start
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Mission;
