import React, { Component } from "react";
import { getUser } from "../../utils/Common";

class BadgeFoto extends Component {
  constructor(props) {
    super(props);
    this.state = { dataUser: null, image: null };
  }
  render() {
    return (
      <div>
        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src={
              this.props.image != null
                ? this.props.image
                : "images/avatars/2s.png"
            }
          />
        </div>
        <div
          className="card card-style preload-img"
          data-src={
            getUser()[0].propic != null
              ? getUser()[0].propic
              : "images/avatars/2s.png"
          }
          data-card-height="55vh"
        >
          <div className="row">
            <div className="card-bottom ml-3">
              <h1 className="font-25 line-height-xl text-center">
                {getUser()[0].name}
                <br />
              </h1>
              <p className="pb-0 mb-0 font-20 text-center">
                {getUser()[0].position}
              </p>
              <div className="text-center mb-3" style={{ marginTop: "10px" }}>
                <a
                  style={{ margin: "5px" }}
                  href={
                    getUser()[0].linkedin == null
                      ? "https://id.linkedin.com/"
                      : getUser()[0].linkedin
                  }
                  className="icon icon-xs rounded-circle shadow-l bg-linkedin"
                >
                  <i className="fab fa-linkedin-in" />
                </a>
                <a
                  style={{ margin: "5px" }}
                  href={
                    getUser()[0].instagram == null
                      ? "https://www.instagram.com/"
                      : getUser()[0].instagram
                  }
                  className="icon icon-xs rounded-circle shadow-l bg-instagram"
                >
                  <i className="fab fa-instagram" />
                </a>
                <a
                  style={{ margin: "5px" }}
                  href={
                    getUser()[0].twitter == null
                      ? "https://twitter.com/"
                      : getUser()[0].twitter
                  }
                  className="icon icon-xs rounded-circle shadow-l bg-twitter"
                >
                  <i className="fab fa-twitter" />
                </a>
                <a
                  style={{ margin: "5px" }}
                  href={
                    getUser()[0].workplace == null
                      ? "https://work.workplace.com/"
                      : getUser()[0].workplace
                  }
                  className="icon icon-xs rounded-circle shadow-l"
                >
                  <img
                    src={"images/icons/workplace.jpg"}
                    className="rounded-circle shadow-l"
                    width={37}
                    height={37}
                    alt="description of image1"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="card-overlay bg-gradient-fade" />
        </div>
      </div>
    );
  }
}

export default BadgeFoto;
