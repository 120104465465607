import React, { Component } from "react";
import { getUser, getImage } from "../utils/Common";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getKota,
  getProfileUser,
  updatePicture,
  updateProfile,
} from "../utils/Repository";
import moment from "moment";
import $ from "jquery";

class Customize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      im3_prepaid: getUser()[0].im3_prepaid,
      im3_postpaid: getUser()[0].im3_postpaid,
      nickname: getUser()[0].nickname,
      color: getUser()[0].favcolor,
      hoby: getUser()[0].hobby,
      linkedin: getUser()[0].linkedin,
      twitter: getUser()[0].twitter,
      workplace: getUser()[0].workplace,
      instagram: getUser()[0].instagram,
      tglLahir: getUser()[0].date_of_birth,
      tempatLahir: getUser()[0].id_kotkab,
      error: "",
      loading: false,
      dataKota: [],
      options: [],
      loadingSelect: true,
      selectedDay: undefined,
    };
    this.onChange = this.onChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
  }
  handleDayChange(day) {
    this.setState({ selectedDay: day });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeSelect(value) {
    console.log(value);
    this.setState({ tempatLahir: value });
  }

  clickimage() {
    document.getElementById("uploadimage").click();
  }
  clickDate() {
    // window.$("#tglLahir").focus();
    window.$("#tglLahir").on("change", function () {
      var date = $(this).val();
      // $(".date").val(date);
    });
  }

  renderList() {
    return this.state.dataKota.map((data) => ({
      label: data.kotkab,
      value: data.id,
    }));
  }

  load() {
    return getKota()
      .then((resp) => resp.data)
      .then((data) => this.setState({ dataKota: data.result }));
  }
  postData() {
    let form = new FormData();
    form.append("nik", getUser()[0].nik);
    form.append("im3_prepaid", this.state.im3_prepaid);
    form.append("im3_postpaid", this.state.im3_postpaid);
    form.append("nickname", this.state.nickname);
    form.append("hobby", this.state.hoby);
    form.append("favcolor", this.state.color);
    form.append("instagram", this.state.instagram);
    form.append("twitter", this.state.twitter);
    form.append("linkedin", this.state.linkedin);
    form.append("workplace", this.state.workplace);
    form.append("tglLahir", this.state.tglLahir);
    form.append("tempatLahir", this.state.tempatLahir);

    return updateProfile(form).then((result) => {
      this.setState({ loading: true });
      if (result.data.message == "user profile successfully updated") {
        this.setState({ loading: false });
        window.$("#toast-3").toast("show");
        setTimeout(this.successSubmit, 1000);
      } else {
        window.$("#toast-4").toast("show");
      }
    });
  }

  findKota() {
    var option = {};
    var find = this.state.dataKota
      .filter((x) => x.id === this.state.tempatLahir)
      .map((x) => (option = { value: x.id, label: x.kotkab }));
    return option;
  }

  successSubmit() {
    getProfileUser(getUser()[0].nik);
  }
  renderoption() {
    // return this.state.dataKota.map((data) => (data.id ===));
  }
  componentDidMount() {
    this.load();
  }

  postPicture(e) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(e.target.files[0]);
    let form = new FormData();
    form.append("nik", getUser()[0].nik);

    fileReader.onload = () => {
      form.append("img", fileReader.result);
      return updatePicture(form).then((result) => {
        console.log(result);
        if (result.data.message == "User profile picture updated") {
          window.$("#toast-3").toast("show");
          getProfileUser(getUser()[0].nik);
        } else {
          window.$("#toast-4").toast("show");
        }
      });
    };

    fileReader.onerror = (error) => {};
  }

  render() {
    const user = getUser();
    const imgae = getImage();
    const selected = (this.state.tglLahir && this.state.tglLahir != '0000-00-00') && moment(this.state.tglLahir).toDate();
    const value = this.findKota();
    console.log(value);
    return (
      <div>
        <div
          id="toast-3"
          className="toast toast-tiny toast-bottom bg-green1-dark"
          data-delay={3000}
          data-autohide="true"
        >
          <i className="fa fa-check mr-3" />
          Successfully
        </div>
        <div
          id="toast-4"
          className="toast toast-tiny toast-bottom bg-red2-dark"
          data-delay={3000}
          data-autohide="true"
        >
          <i className="fa fa-times mr-3" />
          Opps,Failed
        </div>

        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src="images/pictures/20s.jpg"
          />
        </div>
        <div className="card card-style preload-img" data-card-height="55vh">
          <img
            className="text-center"
            src={
              getUser()[0].propic == null
                ? "images/avatars/5s.png"
                : getUser()[0].propic
            }
            width={"auto"}
            height={"70%"}
          />

          <div
            className="card-top ml-3 mr-3 mt-3"
            onClick={() => this.clickimage()}
          >
            <h1 className="float-right">
              <i className="fa fa-edit color-red1-dark" />
            </h1>
          </div>
          <div className="card-bottom ml-3">
            <h1 className="font-40 line-height-xl">{getUser()[0].nama}</h1>
            <p>{getUser()[0].jabatan}</p>
          </div>
          <div className="card-overlay bg-gradient-fade" />
        </div>
        <div className="row">
          <div className={"col-12 col-lg-6 pr-lg-0"}>
            <div className="card card-style" style={{ height: 800 }}>
              <div className="content mb-0">
                <h3 className="font-600">Basic Info</h3>
                <p></p>
                <label> Im3 Prepaid</label>
                <div className="input-style input-style-2 input-required mb-">
                  {/* <span className="color-highlight input-style-1-active">
                    Im3 Prepaid
                  </span> */}
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Im3 Prepaid"
                    name={"im3_prepaid"}
                    onChange={this.onChange}
                    defaultValue={
                      user[0].im3_prepaid == "null" ? "" : user[0].im3_prepaid
                    }
                  />
                </div>
                <label>Im3 Postpaid</label>
                <div className="input-style input-style-2 input-required mb-">
                  {/* <span className="color-highlight input-style-1-active">
                    Im3 Postpaid
                  </span> */}
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Im3 Postpaid"
                    name={"im3_postpaid"}
                    onChange={this.onChange}
                    defaultValue={
                      user[0].im3_postpaid == "null" ? "" : user[0].im3_postpaid
                    }
                  />
                </div>
                <div>
                  <label>Select place of birth</label>
                  <Select
                    defaultValue={this.state.tempatLahir}
                    value={this.findKota()}
                    onChange={(e) => this.setState({ tempatLahir: e.value })}
                    options={this.renderList()}
                    placeholder={"Select place of birth"}
                  />
                </div>
                <br />
                <label>Select your Birthday</label>
                <div className="input-style input-style-2 input-required mb-4">
                  {/* <span
                    className="color-highlight input-style-1-active"
                    style={{ zIndex: "auto" }}
                  >
                    Select your Birthday
                  </span> */}
                  <DatePicker
                    wrapperClassName="form-control"
                    selected={selected || null}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) => {
                      var dateFormat = moment(date).format("yyyy-MM-DD")
                      // console.log(dateFormat)
                      this.setState({ tglLahir: dateFormat })
                    }}
                  />
                </div>
                <label> Nickname</label>
                <div className="input-style input-style-2 input-required mb-">
                  {/* <span
                    className="color-highlight input-style-1-active"
                    style={{ zIndex: "auto" }}
                  >
                    Nickname
                  </span> */}

                  <input
                    className="form-control"
                    type="text"
                    name={"nickname"}
                    placeholder="Nickname"
                    onChange={this.onChange}
                    defaultValue={
                      user[0].nickname == "null" ? "" : user[0].nickname
                    }
                  />
                </div>
                <label>Favorite Color</label>
                <div className="input-style input-style-2 input-required mb-4">
                  {/* <span
                    className="color-highlight input-style-1-active"
                    style={{ zIndex: "auto" }}
                  >
                    Favorite Color
                  </span> */}
                  <input
                    className="form-control"
                    type="text"
                    name={"color"}
                    placeholder="Favorite Color"
                    onChange={this.onChange}
                    defaultValue={
                      user[0].favcolor == "null" ? "" : user[0].favcolor
                    }
                  />
                </div>
                <label>Hoby</label>
                <div className="input-style input-style-2 input-required mb-4">
                  {/* <span
                    className="color-highlight input-style-1-active"
                    style={{ zIndex: "auto" }}
                  >
                    Hobby
                  </span> */}
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Hobby"
                    name={"hoby"}
                    onChange={this.onChange}
                    defaultValue={user[0].hobby == "null" ? "" : user[0].hobby}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"col-12 col-lg-6 pr-lg-0"}>
            <div className="card card-style">
              <div className="content mb-2">
                <h3 className="font-600">Socials</h3>
                <p></p>
                <div className="input-style input-style-2 input-required mb-4">
                  <span className="color-highlight input-style-1-active">
                    <i className="fab font-14 fa-linkedin-in color-linkedin" />
                  </span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Linked In"
                    name={"linkedin"}
                    onChange={this.onChange}
                    defaultValue={
                      user[0].linkedin == "null" ? "" : user[0].linkedin
                    }
                  />
                </div>
                <div className="input-style input-style-2 input-required mb-4">
                  <span className="color-highlight input-style-1-active">
                    <i className="fab font-14 fa-twitter color-twitter" />
                  </span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Twitter"
                    name={"twitter"}
                    onChange={this.onChange}
                    defaultValue={
                      user[0].twitter == "null" ? "" : user[0].twitter
                    }
                  />
                </div>
                <div className="input-style input-style-2 input-required mb-4">
                  <span className="color-highlight input-style-1-active">
                    <i className="fab font-14 fa-instagram color-instagram" />
                  </span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Instagram"
                    name={"instagram"}
                    onChange={this.onChange}
                    defaultValue={
                      user[0].instagram == "null" ? "" : user[0].instagram
                    }
                  />
                </div>
                <div className="input-style input-style-2 input-required mb-4">
                  <span className="color-highlight input-style-1-active">
                    <img
                      src={"images/icons/workplace1.png"}
                      className="rounded-circle shadow-l"
                      width={17}
                      height={17}
                      alt="description of image1"
                    />
                    {/* <i className="fab font-14 fa-instagram color-instagram"/> */}
                  </span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Workspace"
                    name={"workplace"}
                    onChange={this.onChange}
                    defaultValue={
                      user[0].workplace == "null" ? "" : user[0].workplace
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ margin: "10px" }}>
          <button
            style={{ width: "100%" }}
            onClick={() => this.postData()}
            className="btn btn-m mt-4 rounded-xl bg-indosat-merah-dark btn-full  rounded-sm text-uppercase font-900"
          >
            {this.state.loading ? (
              <i className="fas fa-circle-notch fa-spin" />
            ) : (
              ""
            )}
            {this.state.loading ? "Please Wait" : "Save"}
          </button>
        </div>
        <div
          style={{ cursor: "pointer", display: "none" }}
          onClick={this.clickimage}
        >
          <div className="card card-style">
            <div className="content pb-0">
              <p className="mb-0"></p>
              <center>
                <h1 className="center-text pt-4">
                  <i
                    data-feather="image"
                    data-feather-line={1}
                    data-feather-size={50}
                    data-feather-color="blue2-dark"
                    data-feather-bg="blue2-fade-light"
                  ></i>
                </h1>
                Upload Picture
                <input
                  type="file"
                  id="uploadimage"
                  hidden
                  onChange={(e) => this.postPicture(e)}
                  accept="image/*"
                />
              </center>
              <p />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Customize;
