import React, { Component, useState } from "react";
import queryString from "query-string";
import { getUser } from "../../utils/Common";
import { getQuest, SubmitMission } from "../../utils/Repository";
import ListInput from "./Component/ListInput";
import { Link } from "react-router-dom";
import $ from "jquery";
class Checkpoint extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      dataQuest: [],
      dataEssay: [],
      dataChech: [],
      dataInput: [],
      raceId: "",
      stage: "",
      comp: "",
      loading: false,
      timeout: false,
    };
    this.onChange = this.onChange.bind(this);
  }
  getApi() {
    let q = queryString.parse(this.props.location.search);

    this.setState({ raceId: q.raceid, stage: q.stage, comp: q.comp });
    return getQuest(getUser()[0].nik, q.missionid)
      .then((resp) => resp.data)
      .then((data) => this.setState({ dataQuest: data.result }))
      .catch((error) => console.log(error));
  }
  componentDidMount() {
    this.getApi();
  }

  submitMission = (data,e) => {
    e.preventDefault();
    let q = queryString.parse(this.props.location.search);
    this.setState({ loading: true });
    SubmitMission(data, q.missionid)
      .then((result) => {
        if (
          result.data.message == "Data Processed" &&
          result.data.error == false
        ) {
          this.setState({ loading: false });
          window.$("#toast-3").toast("show");
          setTimeout(
            this.successSubmit,
            1000,
            this.state.raceId,
            this.state.stage
          );
        } else {
          window.$("#toast-4").toast("show");
        }
      })
      .catch((error) => {
        var msg = error.message;
        if (msg.includes("timeout")) {
          console.error(error);
          this.setState({ loading: false, timeout: true });
          window.$("#toast-4").toast("show");
        } else {
          window.$("#toast-4").toast("show");
          this.setState({ loading: false });
        }
      });
  };
  successSubmit(raceid, stage) {
    // window.location.assign(
    //   `/Neorace/stage?raceId=${raceid}&stage=${stage}&cStatge=1`
    // );
    // this.context.router.transitionTo(`/Neorace/stage?raceId=${raceid}&stage=${stage}`);
  }
  onChange(data) {
    if (this.state.dataInput.length == 0) {
      this.state.dataInput.push(data);
    } else {
      var checkdata = this.state.dataInput.find(
        (element) => element.id == data.id
      );
      if (checkdata) {
        var findIndex = this.state.dataInput.findIndex(
          (element) => element.id == data.id
        );
        this.state.dataInput[findIndex] = data;
      } else {
        this.state.dataInput.push(data);
      }

      console.log(this.state.dataInput);
    }
  }

  render() {
    const listQuest = this.state.dataQuest && this.state.dataQuest.map((data, index) => (
      <ListInput
        dataJSON={data}
        key={index}
        no={index + 1}
        onChange={this.onChange.bind(this)}
      />
    ));
    return (
      <div>
        <div
          id="toast-3"
          className="toast toast-tiny toast-bottom bg-green1-dark"
          data-delay={3000}
          data-autohide="true"
        >
          <i className="fa fa-check mr-3" />
          Successfully
        </div>
        <div
          id="toast-4"
          className="toast toast-tiny toast-bottom bg-red2-dark"
          data-delay={3000}
          data-autohide="true"
        >
          <i className="fa fa-times mr-3" />
          {this.state.timeout
            ? "Sorry!, please check yout connection"
            : "Sorry!, something wrong"}
        </div>
        <div className="card card-style history-list">
          <div className="content mb-0">
            <h3>Mission </h3>
            <p>Here will be description of the mission itself.</p>
            {listQuest}
            <br></br>
            <div className="row mb-0">
              <div className="col-6">
                <div
                  onClick={(e) => this.props.history.goBack()}
                  className="btn btn-m btn-full rounded-xl shadow-xl text-uppercase font-900 bg-indosat-kuning-dark"
                >
                  Cancel
                </div>
              </div>
              <div className="col-6">
                {this.state.comp == "true" ? (
                  <div className="btn btn-m btn-full rounded-xl mb-3 text-uppercase font-900 shadow-s bg-green1-dark">
                    Completed
                  </div>
                ) : !this.state.loading ? (
                  <button
                    style={{ width: "100%" }}
                    onClick={(e) => this.submitMission(this.state.dataInput,e)}
                    className="btn btn-m btn-full rounded-xl mb-3 text-uppercase font-900 shadow-s bg-indosat-merah-dark"
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    disabled
                    style={{ width: "100%" }}
                    className="btn btn-m btn-full rounded-xl mb-3 mr-2 text-uppercase font-900 shadow-s bg-indosat-merah-dark"
                  >
                    <i className="fas fa-spinner fa-spin"></i>
                    &nbsp; Please Wait
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Checkpoint;
