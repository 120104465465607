import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getBatchChat } from "../utils/Repository";
class Chatlist extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  getData = () => {
    return getBatchChat()
      .then((resp) => resp.data)
      .then((data) => this.setState({ data: data }));
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const list = this.state.data.map((data, index) => {
      return (
        <Link to={`/Chatroom/${data.id}`}>
          <i className="fa font-18 fa-users color-red2-dark" />
          <span>{`${data.name}`}</span>
          <strong>{`${data.description}`}</strong>
          <i className="fa fa-angle-right" />
        </Link>
      );
    });
    return (
      <div>
        {/* <BadgePicture dataUser={user} image={imgae} /> */}
        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div className="card-bg preload-img" />
        </div>
        <div className="card card-style">
          <div className="content mb-2">
            <div className="list-group list-custom-large">{list}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chatlist;
