import Axios from "axios";
import React, { Component } from "react";
import { getUser } from "../../utils/Common";
import RecenrActivitiy from "./component/RecenrActivitiy";
import { getRecentActivities } from "../../utils/Repository";

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = { dataActivity: [] };
  }

  fecth = () => {
    let dataform = new FormData();
    dataform.append("nik", getUser()[0].nik);
    dataform.append("regdate", getUser()[0].regdate);
    return getRecentActivities(dataform)
      .then((resp) => resp.data)
      .then((data) => data.result)
      .then((dataActivity) => this.setState({ dataActivity: dataActivity }));
  };
  componentDidMount() {
    this.fecth();
  }
  render() {
    const list = this.state.dataActivity.map((data, index) => (
      <RecenrActivitiy dataActivity={data} key={index} />
    ));
    return (
      <div
        className="tab-content pb-5"
        id="tab-2"
        style={{ height: "78%", overflowY: "scroll" }}
      >
        {list.length != 0 ? list : <h4 className="text-center">No Activity</h4>}
      </div>
    );
  }
}

export default Activity;
