import React, { Component } from "react";
import ReactTextFormat from "react-text-format";
class Essay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className="input-style input-style-2 input-required mb-4">
          <label>
            {this.props.props.no + "."}
            <ReactTextFormat linkTarget="_blank">
              {this.props.props.dataJSON.name}
            </ReactTextFormat>
          </label>
          <input
            type="text"
            className="form-control"
            id={`essay${this.props.props.dataJSON.id}`}
            required
            defaultValue={this.props.props.dataJSON.answer}
            onChange={(e) =>
              this.props.props.onChange({
                id: this.props.props.dataJSON.id,
                answer: e.target.value,
              })
            }
          />
        </div>
      </div>
    );
  }
}

export default Essay;
