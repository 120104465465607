import React, { Component } from "react";
import moment from "moment";
const RecenrActivitiy = ({ dataActivity }) => {
  return (
    <div>
      <div className="clearfix" />
      <div
        className="speech-bubble color-white bg-gray3-dark"
        style={{ maxWidth: "100%!important" }}
      >
        {dataActivity.nu_nama} {dataActivity.nc_title}
        <br />
        <p className="text-center mb-0 font-11 color-white float-right">
          {moment(dataActivity.timecompleted, "YYYYMMDD").fromNow()}
        </p>
      </div>
    </div>
  );
};

export default RecenrActivitiy;
