// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem("result");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};
export const getTokenFcm = () => {
  return sessionStorage.getItem("fcmtoken") || null;
};

export const getImage = () => {
  return sessionStorage.getItem("image") || null;
};

export const getCeo = () => {
  return sessionStorage.getItem("ceo") || null;
};
export const getButtonSurvey = () => {
  return sessionStorage.getItem("disable") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("result");
  sessionStorage.removeItem("image");
  sessionStorage.removeItem("ceo");
  sessionStorage.removeItem("role");
  sessionStorage.removeItem("fcmtoken");
  sessionStorage.removeItem("disable");
};

export const setRole = (role) => {
  sessionStorage.setItem("role", role);
};

export const getRole = () => {
  return sessionStorage.getItem("role") || null;
};

export const setImage = (image) => {
  sessionStorage.setItem("image", image);
};
export const setCeo = (ceo) => {
  sessionStorage.setItem("ceo", ceo);
};

export const setUser = (user) => {
  sessionStorage.setItem("result", JSON.stringify(user));
};
// set the token and user from the session storage
export const setUserSession = (token) => {
  sessionStorage.setItem("token", token);
};


export const setTokenFcm = (token) => {
  sessionStorage.setItem("fcmtoken", token)
}


export const setToken = (token) => {
  sessionStorage.setItem("token", token)
}

export const buttonSurvey = (disable) => {
  sessionStorage.setItem("disable", disable)
}

