import React, { Component } from "react";
class ModalContactBuddy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    console.log(this.props.getDataContactPerson);
    return (
      <div
        id="menu-share-list2"
        className="menu menu-box-bottom menu-box-detached rounded-m"
        data-menu-height={100}
        data-menu-effect="menu-over"
      >
        <div className="list-group list-custom-small pl-1 pr-3">
          {this.props.getDataContactPerson.phone == "-" ||
          this.props.getDataContactPerson.phone == null ? (
            <a href={`#!`}>
              <i
                className={
                  this.props.jenis == "phone"
                    ? "font-18 fas fa-phone color-gray2-dark"
                    : "font-18 fas fa-comment color-gray2-dark"
                }
              />
              <span className="font-13">
                {this.props.jenis == "phone" ? "Call(Phone)" : "Message"}
              </span>
              <i className="fa fa-angle-right" />
            </a>
          ) : (
            <a
              href={
                this.props.jenis == "phone"
                  ? `tel:${this.props.getDataContactPerson.phone}`
                  : `sms:${this.props.getDataContactPerson.phone}?body=Hello`
              }
            >
              <i
                className={
                  this.props.jenis == "phone"
                    ? "font-18 fas fa-phone color-red1-dark"
                    : "font-18 fas fa-comment color-red1-dark"
                }
              />
              <span className="font-13">
                {this.props.jenis == "phone" ? "Call(Phone)" : "Message"}
              </span>
              <i className="fa fa-angle-right" />
            </a>
          )}

          {this.props.getDataContactPerson.email == "-" ||
          this.props.getDataContactPerson.email == null ? (
            <a href={`#!`}>
              <i className="font-18 fas fa-users color-gray2-dark" />
              <span className="font-13">Microsoft Teams</span>
              <i className="fa fa-angle-right" />
            </a>
          ) : (
            <a
              href={
                this.props.jenis == "Phone"
                  ? `https://teams.microsoft.com/l/call/0/0?users=${this.props.getDataContactPerson.email}`
                  : `https://teams.microsoft.com/l/chat/0/0?users=${this.props.getDataContactPerson.email}`
              }
            >
              <i className="font-18 fas fa-users color-red1-dark" />
              <span className="font-13">Microsoft Teams</span>
              <i className="fa fa-angle-right" />
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default ModalContactBuddy;
