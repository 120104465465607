import React, { Component } from "react";
import Axios from "axios";
import { setImage, getUser } from "../../utils/Common";

class EditPicture extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  clickimage() {
    document.getElementById("uploadimage").click();
  }

  onChangeImage = async (e) => {
    const files = e.target.files;
    let data = new FormData();
    let user = getUser()
    console.log(files)
    // data.append("username", user.NIK);
    // data.append("type", "avatar");
    // data.append("image", files[0]);
    // Axios.post(`https://appsorientasi.indosatooredoo.com/dev/apiuser/profilmaterial`, data).then((response) => {
    //   if (response.status == 200) {
    //     let username = new FormData();
    //     username.append("username", user.NIK);
    //     Axios.post(`https://appsorientasi.indosatooredoo.com/dev/apiuser/getuserimage`, username).then((response) => {
    //       if (response.status == 200) {
    //         console.log(response.data.result.avatarImage);
    //         setImage(response.data.result.avatarImage);
    //         window.location.reload(true);
    //       }
    //     });
    //   }
    // });
  };
  render() {
    return (
      <div className="row mr-0 ml-0 mb-0">
        <div
          className="col-4 pl-0 pr-0"
          style={{ cursor: "pointer" }}
          onClick={this.clickimage}
        >
          <div className="card card-style">
            <div className="content pb-0">
              <p className="mb-0"></p>
              <center>
                <h1 className="center-text pt-4">
                  <i
                    data-feather="file"
                    data-feather-line={1}
                    data-feather-size={50}
                    data-feather-color="blue2-dark"
                    data-feather-bg="blue2-fade-light"
                  ></i>
                </h1>
                Upload File
                <input
                  type="file"
                  id="uploadimage"
                  hidden
                  onChange={this.onChangeImage}
                />
              </center>
              <p />
            </div>
          </div>
        </div>
        <div className="col-4 pr-0 pl-0">
          <div className="card card-style">
            <div className="content pb-0">
              <p className="mb-0"></p>
              <center>
                <h1 className="center-text pt-4">
                  <i
                    data-feather="image"
                    data-feather-line={1}
                    data-feather-size={50}
                    data-feather-color="green1-dark"
                    data-feather-bg="green1-fade-light"
                  ></i>
                </h1>
                Take Photo
              </center>
              <p />
            </div>
          </div>
        </div>
        <div className="col-4 pr-0 pl-0">
          <div className="card card-style">
            <div className="content pb-0">
              <p className="mb-0"></p>
              <center>
                <h1 className="center-text pt-4">
                  <i
                    data-feather="x"
                    data-feather-line={1}
                    data-feather-size={50}
                    data-feather-color="green1-dark"
                    data-feather-bg="green1-fade-light"
                  ></i>
                </h1>
                Remove Picture
              </center>
              <p />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPicture;
