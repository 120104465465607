import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getUser } from "../../utils/Common";
import { getMission } from "../../utils/Repository";
import queryString from "query-string";
import ListStage from "./Component/ListStage";
class Stage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataStage: [],
      raceid: "",
      stage: "",
      countStage: "",
      dataCompletion: [],
    };
  }

  getApi = () => {
    let q = queryString.parse(this.props.location.search);
    this.setState({ stage: q.stage, raceid: q.raceId, countStage: q.cStage });
    return getMission(getUser()[0].nik, q.raceId, q.stage)
      .then((resp) => resp.data)
      .then((data) => {
        this.setState({ dataStage: data.result });
        this.state.dataStage.map((data, index) => {
          if (data.is_complete == "TRUE") {
            this.state.dataCompletion.push(true);
          }
        });
      })
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    this.getApi();
  }

  onClick(raceid, stage) {
    window.location.href = `/Neorace/mission?raceId=${raceid}&stage=${stage}`;
  }

  showListShare() {
    window.$("#menu-share-list").showMenu();
    window.$("#menu-share-list").appendTo("body");
  }
  render() {
    const listdata = this.state.dataStage && this.state.dataStage.map((data, index) => (
      <ListStage dataJSON={data} key={index} stage={this.state.stage} />
    ));
    return (
      <div>
        <div className="card card-style history-list">
          <div className="content mb-0">
            <h3 className="font-700">Your Missions</h3>
            <p>Here are list of missions required to finish the stage.</p>
          </div>
          <div className="accordion" id="accordion-1">
            {listdata}
          </div>
        </div>
        <br />
        {this.state.stage != this.state.countStage &&
        this?.state?.dataStage?.length ==
          this?.state?.dataCompletion?.length.toString() ? (
          <Link
            to={`/Neorace/stage?raceId=${this.state.raceid}&stage=${
              parseInt(this.state.stage) + 1
            }&cStage=${this.state.countStage}`}
            className="btn btn-full rounded-xl btn-margins bg-indosat-merah-dark rounded-sm shadow-xl btn-m text-uppercase font-900 history-list"
          >
            NEXT STAGE
          </Link>
        ) : (
          ""
        )}
        <br />
        <div
          onClick={() => this.showListShare()}
          className="btn btn-full rounded-xl btn-margins bg-indosat-merah-dark rounded-sm shadow-xl btn-m text-uppercase font-900 history-list"
        >
          Share
        </div>

        <div
          id="menu-share-list"
          className="menu menu-box-bottom menu-box-detached rounded-m"
          data-menu-height={310}
          data-menu-effect="menu-over"
        >
          <div className="list-group list-custom-small pl-1 pr-3">
            <a href="#" className="shareToFacebook">
              <i className="font-18 fab fa-facebook color-facebook" />
              <span className="font-13">Facebook</span>
              <i className="fa fa-angle-right" />
            </a>
            <a href="https://twitter.com/share" className="shareToTwitter">
              <i className="font-18 fab fa-twitter-square color-twitter" />
              <span className="font-13">Twitter</span>
              <i className="fa fa-angle-right" />
            </a>
            <a
              href="https://www.linkedin.com/sharing/share-offsite"
              className="shareToLinkedIn"
            >
              <i className="font-18 fab fa-linkedin color-linkedin" />
              <span className="font-13">LinkedIn</span>
              <i className="fa fa-angle-right" />
            </a>
            <a
              href="whatsapp://send?text=The text to share!"
              data-action="share/whatsapp/share"
              className="shareToWhatsApp"
            >
              <i className="font-18 fab fa-whatsapp-square color-whatsapp" />
              <span className="font-13">WhatsApp</span>
              <i className="fa fa-angle-right" />
            </a>
            <a href="mailto:name@email.com" className="shareToMail border-0">
              <i className="font-18 fa fa-envelope-square color-mail" />
              <span className="font-13">Email</span>
              <i className="fa fa-angle-right" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Stage;
