import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import ModalCeo from "../ModalCeo";
import $ from "jquery";
import ModalVideo from "../ModalVideo";
import Axios from "axios";
import { getUser } from "../../utils/Common";
import { getVideoCeo } from "../../utils/Repository";

class Explore extends Component {
  constructor(props) {
    super(props);
    this.state = { urlVideoCeo: "" };
  }

  getVideoCeo = () => {
    return getVideoCeo().then((respone) => {
      if (respone.data.result.last_duration == null) {
        this.setState({ urlVideoCeo: respone.data.result.videoUrl });
      } else {
        var last = `${respone.data.result.videoUrl}?t=${respone.data.result.last_duration}`;
        this.setState({ urlVideoCeo: last });
      }
    });
  };

  componentDidMount() {
    this.getVideoCeo();
  }

  submitHandler = (cat, pdf) => (evt) => {
    evt.preventDefault();
    let { history } = this.props;
    history.push("/Classroom/viewexplore?cat=" + cat);
  };

  onClickModal = (e) => {
    e.preventDefault();
    window.$("#exampleModal").show("toggle");
    console.log("test");
  };

  render() {
    return (
      <div>
        <div className="card card-style">
          <div className="content mt-0 pt-2 mb-2">
            <h5>Did you know?</h5>
            <p>
              All videos and e-book content below we provide special only for
              you, so that you can understand and increase your knowledge about
              Indosat PT. Tbk.
            </p>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-12 col-lg-6 pr-lg-0">
              <div className="card card-style">
                <div className="content mt-0 mb-2">
                  <div className="list-group list-custom-small list-icon-0">
                    <a
                      href={"/Classroom/viewexplore?cat=business_knowledge"}
                      onClick={this.submitHandler("business_knowledge")}
                    >
                      <i className="fa fa-video color-red2-dark" />
                      <span className="font-14">Business Knowledge</span>
                      <i className="fa fa-angle-right" />
                    </a>
                    <a
                      href={"/Classroom/viewexplore?cat=company_profile"}
                      onClick={this.submitHandler("company_profile")}
                    >
                      <i className="fa fa-video color-red2-dark" />
                      <span className="font-14">Company Profile</span>
                      <i className="fa fa-angle-right" />
                    </a>
                    <a
                      href={"/Classroom/viewexplore?cat=culture_values"}
                      onClick={this.submitHandler("culture_values")}
                    >
                      <i className="fa fa-video color-red2-dark" />
                      <span className="font-14">Culture values</span>
                      <i className="fa fa-angle-right" />
                    </a>
                    <a
                      href={"/Classroom/viewexplore?cat=hse"}
                      onClick={this.submitHandler("hse")}
                    >
                      <i className="fa fa-video color-red2-dark" />
                      <span className="font-14">
                        Health , Safety & Enviroment
                      </span>
                      <i className="fa fa-angle-right" />
                    </a>
                    <a
                      href={"/Classroom/viewexplore?cat=indosat_my_info"}
                      onClick={this.submitHandler("indosat_my_info")}
                    >
                      <i className="fa fa-video color-red2-dark" />
                      <span className="font-14">Indosat My Info</span>
                      <i className="fa fa-angle-right" />
                    </a>

                    <a
                      href="#!"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      <i className="fa fa-video color-red2-dark" />
                      <span className="font-14">Opening Speech</span>
                      <i
                        className="fa fa-angle-right"
                        style={{ float: "right" }}
                      />
                    </a>
                    <a
                      href={"/Classroom/viewexplore?cat=performance_management"}
                      onClick={this.submitHandler("performance_management")}
                    >
                      <i className="fa fa-video color-red2-dark" />
                      <span className="font-14">Performance Management</span>
                      <i className="fa fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 pr-lg-0">
              <div className="card card-style">
                <div className="content mt-0 mb-2">
                  <div className="list-group list-custom-small list-icon-0">
                    <a
                      href={"/Classroom/viewexplore?cat=reading_material"}
                      onClick={this.submitHandler("reading_material")}
                      className="no-border"
                    >
                      <i className="fa fa-book color-yellow1-dark" />
                      <span className="font-14">Reading Material</span>
                      <i className="fa fa-angle-right" />
                    </a>
                    <a
                      href={"/Classroom/playbook"}
                      // onClick={this.submitHandler("reading_material")}
                      className="no-border"
                    >
                      <i className="fa fa-book color-yellow1-dark" />
                      <span className="font-14">Playbook</span>
                      <i className="fa fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalVideo urlVideoCeo={this.state.urlVideoCeo} />
      </div>
    );
  }
}

export default withRouter(Explore);
