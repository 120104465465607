import React, { Component } from "react";

class MyBadgesViewAll extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onClick = () => {
    window.$("#menu-success-1").showMenu();
    this.props.getData(this.props.myBadges);
    window.$("#menu-success-1").appendTo("body");
  };
  render() {
    //   Item Badges View ALL
    return (
      <div
        className="text-center col-3"
        onClick={() => this.onClick()}
        style={{ display: this.props.myBadge, marginBottom: "12px" }}
      >
        <img
          src={
            this.props.myBadges.imgurl != null
              ? this.props.myBadges.imgurl
              : "https://neo.deprakoso.com/assets/badges/Learner.png"
          }
          width={50}
          height={50}
          className={
            this.props.myBadges.value == "3"
              ? "rounded-xl owl-lazy shadow-l bg-gradient-dark1"
              : this.props.myBadges.value == "2"
              ? "rounded-xl owl-lazy shadow-l bg-gradient-yellow1"
              : "rounded-xl owl-lazy shadow-l bg-gradient-gray2"
          }
        />
        {/* <p>{this.props.myBadges.name}</p> */}
      </div>
    );
  }
}

export default MyBadgesViewAll;
