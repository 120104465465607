import React, { Component } from "react";
import { Link } from "react-router-dom";
class NavBarBot extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="footer-bar" className="footer-bar-5">
        <Link to="/Neorace">
          <i
            data-feather="flag"
            data-feather-line={1}
            data-feather-size={21}
            data-feather-color="red2-dark"
          />
          <span>Neo Race</span>
        </Link>
        <Link to="/dashboard">
          <i
            data-feather="home"
            data-feather-line={1}
            data-feather-size={21}
            data-feather-color="red2-dark"
          />
          <span>Home</span>
        </Link>
        <Link to="/Classroom">
          <i
            data-feather="book-open"
            data-feather-line={1}
            data-feather-size={21}
            data-feather-color="red2-dark"
          />
          <span>Classroom</span>
        </Link>
      </div>
    );
  }
}

export default NavBarBot;
