import React, { Component } from "react";

class PageApp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="card card-style">
        <div className="content mb-2">
          <h3>My Access</h3>
          <div className="list-group list-custom-large">
            <a href="#">
              <i className="fab font-14 fa-react rounded-sm shadow-m bg-red2-dark" />
              <span>Mylearning</span>
              <span className="badge bg-red2-dark">Not active</span>
            </a>
            <a href="#">
              <i className="fab font-14 fa-react rounded-sm shadow-m bg-red2-dark" />
              <span>Workplace Chat</span>
              <span className="badge bg-red2-dark">Not active</span>
            </a>
            <a href="#">
              <i className="fab font-14 fa-react rounded-sm shadow-m bg-red2-dark" />
              <span>MyDev</span>
              <span className="badge bg-red2-dark">Not active</span>
            </a>
            <a href="#">
              <i className="fab font-14 fa-react rounded-sm shadow-m bg-red2-dark" />
              <span>ODSys</span>
              <span className="badge bg-red2-dark">Not active</span>
            </a>
            <a href="#">
              <i className="fab font-14 fa-react rounded-sm shadow-m bg-red2-dark" />
              <span>MyHiring</span>
              <span className="badge bg-red2-dark">Not active</span>
            </a>
            <a href="#">
              <i className="fab font-14 fa-react rounded-sm shadow-m bg-red2-dark" />
              <span>SAP</span>
              <span className="badge bg-red2-dark">Not active</span>
            </a>
            <a href="#">
              <i className="fab font-14 fa-react rounded-sm shadow-m bg-red2-dark" />
              <span>Talent Management</span>
              <span className="badge bg-red2-dark">Not active</span>
            </a>
            <a href="#">
              <i className="fab font-14 fa-react rounded-sm shadow-m bg-red2-dark" />
              <span>Workplace</span>
              <span className="badge bg-red2-dark">Not active</span>
            </a>

          </div>
        </div>
      </div>
    );
  }
}

export default PageApp;
