import React, { Component } from "react";
import OpenApp from "react-open-app";
import { BrowserView, MobileView, isBrowser, isMobile, isAndroid, isIOS } from "react-device-detect";
class Apps extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className="card card-style bg-theme px-3 pt-2 history-list">
          <div className="accordion" id="accordion-1">
            <div className="mb-3">
              <OpenApp
                href="https://www.workplace.com/features/workplace-chat"
                android="https://play.google.com/store/apps/details?id=com.facebook.workchat"
                ios="https://apps.apple.com/us/app/workplace-chat/id958124798"
              >
                <section >
                  <div className="user-left">
                    <div className="d-flex">
                      <div>
                        <h5 className="mt-1 mb-0">Workplace chat</h5>
                      </div>
                      <div className="ml-auto">
                        <span className="badge bg-green1-dark color-white">
                          Go To App
                      </span>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </section>
              </OpenApp>

              <div className="divider mb-1 mt-3" />
            </div>
            <div className="mb-3">
              <OpenApp
                href="https://www.workplace.com"
                android="https://play.google.com/store/apps/details?id=com.facebook.work"
                ios="https://apps.apple.com/us/app/workplace-from-facebook/id944921229"
              >
                <section >
                  <div className="user-left">
                    <div className="d-flex">
                      <div>
                        <h5 className="mt-1 mb-0">Workplace</h5>
                      </div>
                      <div className="ml-auto">
                        <span className="badge bg-green1-dark color-white">
                          Go To App
                      </span>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </section>
              </OpenApp>

              <div className="divider mb-1 mt-3" />
            </div>
            <div className="mb-3">
              <OpenApp
                href="https://mylearning.indosatooredoo.com/"
                android="https://play.google.com/store/apps/details?id=com.indosat.mylapi"
                ios="https://mylearning.indosatooredoo.com/ios/"
              >
                <section >
                  <div className="user-left">
                    <div className="d-flex">
                      <div>
                        <h5 className="mt-1 mb-0">Mylearning</h5>
                      </div>
                      <div className="ml-auto">
                        <span className="badge bg-green1-dark color-white">
                          Go To App
                      </span>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </section>
              </OpenApp>

              <div className="divider mb-1 mt-3" />
            </div>
            <div className="mb-3">
              <OpenApp
                href="https://mydev.indosatooredoo.com"
              >
                <section >
                  <div className="user-left">
                    <div className="d-flex">
                      <div>
                        <h5 className="mt-1 mb-0">MyDev</h5>
                      </div>
                      <div className="ml-auto">
                        <span className="badge bg-green1-dark color-white">
                          Go To Website
                      </span>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </section>
              </OpenApp>
              <div className="divider mb-1 mt-3" />
            </div>
            <div className="mb-3">
              <OpenApp
                href="https://odsys.indosatooredoo.com"
              >
                <section>
                  <div className="user-left">
                    <div className="d-flex">
                      <div>
                        <h5 className="mt-1 mb-0">ODSys</h5>
                      </div>
                      <div className="ml-auto">
                        <span className="badge bg-green1-dark color-white">
                          Go To Website
                      </span>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </section>
              </OpenApp>
              <div className="divider mb-1 mt-3" />
            </div>
            <div className="mb-3">
              <OpenApp
                href="https://myhiring.indosatooredoo.com"
              >
                <section >
                  <div className="user-left">
                    <div className="d-flex">
                      <div>
                        <h5 className="mt-1 mb-0">MyHiring</h5>
                      </div>
                      <div className="ml-auto">
                        <span className="badge bg-green1-dark color-white">
                          Go To Website
                      </span>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </section>
              </OpenApp>
              <div className="divider mb-1 mt-3" />
            </div>
            <div className="mb-3">
              <OpenApp
                android="https://play.google.com/store/apps/details?id=com.sap.fiori.client"
              >
                <section >
                  <div className="user-left">
                    <div className="d-flex">
                      <div>
                        <h5 className="mt-1 mb-0">SAP</h5>
                      </div>
                      <div className="ml-auto">
                        <span className="badge bg-green1-dark color-white">
                          Go To App
                      </span>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </section>
              </OpenApp>
              <div className="divider mb-1 mt-3" />
            </div>
          </div>
        </div>
        <div className="card header-card shape-rounded" data-card-height={150}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src="images/pictures/20s.jpg"
          />
        </div>
      </div>
    );
  }
}

export default Apps;
