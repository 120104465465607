import React, { useState, useEffect, useRef } from "react";
import ReactEmoji from "react-emoji";
import "./Message.css";
import { getUser } from "../../../../utils/Common";
import moment from "moment";
import ReactTextFormat from "react-text-format";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { triggerBase64Download } from "react-base64-downloader";
const Message = ({
  message: { msg, timecreated, personnel_number, type },
  name,
}) => {
  const [isOpen, setOpen] = useState(false);
  let isSentByCurrentUser = false;
  var dateString = moment.unix(timecreated).format();
  var date = moment(dateString).fromNow();
  const trimmedName =
    personnel_number != null ? personnel_number.trim().toLowerCase() : name;
  if (getUser()[0].nama.trim().toLowerCase() === trimmedName) {
    isSentByCurrentUser = true;
  }

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, []);
  const pdf = (msg) => {
    window.location.href = msg;
  };
  return isSentByCurrentUser ? (
    <div>
      <div className="clearfix" />
      <div
        className="speech-bubble speech-left color-black mb-3"
      >
        <span className="sentText">{personnel_number}</span>
        {type === "text" ? (
          <ReactTextFormat linkTarget={"_blank"}>
            {ReactEmoji.emojify(msg)}
          </ReactTextFormat>
        ) : isOpen ? (
          <Lightbox mainSrc={msg} onCloseRequest={() => setOpen(false)} />
        ) : type === "file" ? (
          <img
            src={msg}
            style={{ maxHeight: "300px", maxWidth: "200px" }}
            onClick={() => setOpen(true)}
          />
        ) : (
          <a download="neo.pdf" href={msg}>
            Download PDF
          </a>
        )}
        <br />
        <span className="float-right"> {date}</span>
      </div>
      <div className="clearfix" />

      {/* <div className="divider" /> */}
      <div ref={messagesEndRef} />
    </div>
  ) : (
    <div>
      <div className="clearfix" />
      <div
        className="speech-bubble speech-right color-black mb-3"
        style={{ backgroundColor: "white" }}
      >
        <span className="sentText">{personnel_number}</span>
        {type === "text" ? (
          <ReactTextFormat linkTarget={"_blank"}>
            {ReactEmoji.emojify(msg)}
          </ReactTextFormat>
        ) : isOpen ? (
          <Lightbox mainSrc={msg} onCloseRequest={() => setOpen(false)} />
        ) : type === "file" ? (
          <img
            src={msg}
            style={{ maxHeight: "300px", maxWidth: "200px" }}
            onClick={() => setOpen(true)}
          />
        ) : (
          <a download="neo.pdf" href={msg}>
            Download PDF
          </a>
        )}
        <br />
        <span className="float-right"> {date}</span>
      </div>
      <div className="clearfix" />

      {/* <div className="divider" /> */}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default Message;
