import Axios from "axios";
import React, { Component, useState } from "react";
import ReactPlayer from "react-player";
import { getUser } from "../utils/Common";
import { watchOpening, updateCeo } from "../utils/Repository";
const ModalVideo = (urlVideoCeo, play) => {
  const [duration, setDuration] = useState(0);
  const [second, setSecond] = useState(0);
  const [enablePlay, setEnablePlay] = useState(true);
  const handleClose = () => {
    setEnablePlay(false);
    onPause();
  };
  const onPlay = () => {
    setEnablePlay(true);

    console.log(enablePlay);
  };
  const onDuration = (duration) => {
    setDuration(duration);
  };
  const onProgress = (progress) => {
    if (!duration) {
      return;
    }
    const secondsElapsed = progress.played * duration;
    if (secondsElapsed !== second) {
      setSecond(secondsElapsed);
    }

    if (secondsElapsed == duration) {
      let form = new FormData();
      form.append("user_id", getUser()[0].nik);
      return watchOpening(form);
    }
  };
  const onPause = () => {
    return updateCeo(second);
  };
  return (
    <div
      className={"modal fade"}
      id="exampleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Opening Speach</h5>
          </div>
          <div className="modal-body">
            <ReactPlayer
              width="auto"
              height="300px"
              onPause={onPause}
              onStart={onPlay}
              onDuration={onDuration}
              onProgress={onProgress}
              playing={enablePlay ? false : false}
              url={urlVideoCeo.urlVideoCeo}
              controls={true}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClose}
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalVideo;
