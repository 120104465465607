import React, { Component } from "react";
import { isMobile } from "react-device-detect";
class MyBadges extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClick = () => {
    window.$("#menu-success-1").showMenu();
    this.props.getData(this.props.dataBadges);
    window.$("#menu-success-1").appendTo("body");
  };

  render() {
    if (isMobile) {
      return (
        <div className="icon-user" onClick={() => this.onClick()}>
          <img
            data-src={
              this.props.dataBadges.imgurl != null
                ? this.props.dataBadges.imgurl
                : "https://neo.deprakoso.com/assets/badges/Learner.png"
            }
            width={50}
            height={50}
            className={
              this.props.dataBadges.value == "3"
                ? "rounded-xl owl-lazy shadow-l bg-gradient-dark1"
                : this.props.dataBadges.value == "2"
                ? "rounded-xl owl-lazy shadow-l bg-gradient-yellow1"
                : "rounded-xl owl-lazy shadow-l bg-gradient-gray2"
            }
          />
        </div>
      );
    } else {
      return (
        <div
          className="my-badge text-center mb-3"
          onClick={() => this.onClick()}
        >
          <img
            src={
              this.props.dataBadges.imgurl != null
                ? this.props.dataBadges.imgurl
                : "https://neo.deprakoso.com/assets/badges/Learner.png"
            }
            width={50}
            height={50}
            className={
              this.props.dataBadges.value == "3"
                ? "rounded-xl owl-lazy shadow-l bg-gradient-dark1"
                : this.props.dataBadges.value == "2"
                ? "rounded-xl owl-lazy shadow-l bg-gradient-yellow1"
                : "rounded-xl owl-lazy shadow-l bg-gradient-gray2"
            }
          />
          {/* <a>{this.props.dataBadges.name}</a> */}
        </div>
      );
    }
  }
}

export default MyBadges;
