import React, { Component } from "react";
import { withRouter } from "react-router-dom";
class ModalConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = { urlVideoCeo: "", show: false };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
    document.body.classList.remove("modal-open");
    document.getElementById("modalConfirm").classList.remove("show");
    document.getElementById("modalConfirm").classList.remove("d-block");
    console.log(this.state.show);
  }

  handleShow() {
    this.setState({ show: true });
  }
  resetVideo = () => {
    window.location.href = this.props.resetVideo;
  };

  resumetVideo = () => {
    window.location.href = this.props.resumeVideo;
    // console.log(this.props.resumeVideo)
};

  render() {
    return (
      <div>
        <div
          className={"modal fade" + (this.props.ceklastDuration ? " show" : "")}
          id="modalConfirm"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Video</h5>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={this.resumetVideo}
                  className="btn bg-red2-dark rounded-xl"
                >
                  Resume Video
                </button>
                <button
                  type="button"
                  onClick={this.resetVideo}
                  className="btn bg-red2-dark rounded-xl"
                >
                  Reset Video
                </button>
                {/* <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={this.handleClose}
                      data-dismiss="modal"
                    >
                      Close
                    </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModalConfirm);
