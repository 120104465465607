import React, { Component } from "react";
import ReactPlayer from "react-player";
import CardModule from "../component/classroom/component/CardModule";
import Axios from "axios";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { getUser } from "../utils/Common";
import YouTube from "react-youtube";
import { Player } from "video-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getVideoNew, getContentCompletion, videoLastAccess } from "../utils/Repository";
class PlaylistVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pdf: false,
      vid: "",
      cat: "",
      duration: null,
      secondsElapsed: null,
      lastDuration: null,
      content_id: null,
      fromRow: "0",
    };
  }

  fetchData = () => {
    // getdata menggunakan axios
    let q = queryString.parse(this.props.location.search);
    this.setState({
      vid: q.vid,
      lastDuration: q.duration,
      content_id: q.content_id,
    });
    console.log(q.vid);
    if (q.cat != "reading_material") {
      this.setState({ cat: q.cat });
      return getVideoNew(q.cat)
        .then((resp) => resp.data)
        .then((data) => data.result.videos)
        .then((datavideos) =>
          this.setState({ data: datavideos, fromRow: "10" })
        );
    }
  };

  onDuration = (duration) => {
    this.setState({ duration });
  };
  onProgress = (progress) => {
    if (!this.state.duration) {
      return;
    }
    const secondsElapsed = progress.played * this.state.duration;
    if (secondsElapsed !== this.state.secondsElapsed) {
      this.setState({ secondsElapsed });
    }

    if (secondsElapsed == this.state.duration) {
      this.onPlay();
    }
  };

  onPause = () => {
    let formdata = new FormData();
    formdata.append("nik", getUser()[0].nik);
    formdata.append("content_id", this.state.content_id);
    formdata.append("last_access", this.state.secondsElapsed);
    return videoLastAccess(formdata).then((response) => {
      if (response.status == 200) {
      }
    });
  };

  onPlay() {
    if (this.state.secondsElapsed == this.state.duration) {
      let formdata = new FormData();
      formdata.append("user_id", getUser()[0].nik);
      formdata.append("content_id", this.state.content_id);
      return getContentCompletion(formdata).then((response) => {
        if (response.status == 200) {
        }
      });
    }
  }

  scroll = () => {
    let q = queryString.parse(this.props.location.search);
    this.setState({ cat: q.cat });
    return getVideoNew(q.cat, this.state.fromRow)
      .then((resp) => resp.data)
      .then((data) => data.result.videos)
      .then((datavideos) => {
        for (let index = 0; index < datavideos.length; index++) {
          this.setState({ data: this.state.data.concat(datavideos[index]) });
        }
        this.setState({ fromRow: this.state.fromRow + 10 });
      });
  };

  componentDidMount() {
    this.fetchData();
  }

  playVideo = (data) => {
    var getUrl = data.url.split("/");
    window.location.href =
      "/playvideo?cat=" +
      this.state.cat +
      "&vid=" +
      getUrl[3] +
      "&duration=0&content_id=" +
      data.id;
  };

  render() {
    console.log(this.state.duration);
    console.log(this.state.secondsElapsed);
    if (this.state.secondsElapsed == 60) {
      console.log(true);
    }
    // .sort((a, b) => (a.completionState == b.completionState ? 1 : -1))
    const list = this.state.data.map((data, index) => (
      <CardModule
        dataJSON={data}
        key={index}
        pdf={this.state.pdf}
        category={this.state.cat}
      />
    ));
    return (
      <div id={"page"}>
        <div className="row header-fixed">
          <div className="header header-logo-app">
            <a
              href={"#/"}
              data-back-button
              className="header-title header-subtitle"
            >
              Back
            </a>
            <a
              href={"#/"}
              data-back-button
              className="header-icon header-icon-1"
            >
              <i className="fas fa-arrow-left" />
            </a>
          </div>
          <div
            className="col-12"
            style={{ marginBottom: "20px", marginTop: "50px" }}
          >
            <ReactPlayer
              width="auto"
              height="50vh"
              url={
                this.state.lastDuration == null ||
                this.state.lastDuration == "0"
                  ? `https://www.youtube.com/watch?v=${this.state.vid}`
                  : `https://www.youtube.com/watch?v=${this.state.vid}&t=${this.state.lastDuration}`
              }
              controls={true}
              onPause={this.onPause}
              onDuration={this.onDuration}
              onProgress={this.onProgress}
              playing={true}
            />
          </div>
          <div
            className="col-12 mb-n5"
            style={{ maxHeight: "72vh", overflowY: "scroll" }}
          >
            <InfiniteScroll
              className={"video-content"}
              dataLength={this.state.data.length}
              next={this.scroll}
              inverse={true} //
              hasMore={true}
            >
              {list}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PlaylistVideo);
