import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { getUser } from "../../../utils/Common";
import { StartMission } from "../../../utils/Repository";
class ListStage extends Component {
  constructor(props) {
    super(props);
    this.state = { raceid: "", stage: "" };
  }
  onClick = (id, stage) => {
    this.setState({ raceId: q.raceId, stage: q.stage });
    let q = queryString.parse(this.props.location.search);
    window.location.href = `/Neorace/mission?&raceId=${q.raceId}&stage=${stage}&id=${id}`;
  };
  limitChar(text, count) {
    return text.slice(0, count) + (text.length > count ? "..." : "");
  }
  startMission = () => {
    let q = queryString.parse(this.props.location.search);
    this.setState({ raceId: q.raceId, stage: q.stage });
    let form = new FormData();
    form.append("nik", getUser()[0].nik);
    form.append("mission_id", this.props.dataJSON.id);
    return StartMission(form).then((result) => {
      console.log(result.data.message);
      if (
        (result.data.message == "User already start the missions" ||
          result.data.message == "Mission successfully started") &&
        result.data.error == false
      ) {
        window.location.assign(
          `/Neorace/checkpoint?missionid=${this.props.dataJSON.id}&raceid=${
            this.state.raceId
          }&stage=${this.state.stage}&comp=${
            this.props.dataJSON.is_complete == null ||
            this.props.dataJSON.is_complete == "FALSE"
              ? false
              : true
          }`
        );
      } else {
        window.$("#toast-4").toast("show");
      }
    });
  };
  render() {
    return (
      <div className="mb-0">
        <button
          className="btn accordion-btn border-0"
          data-toggle="collapse"
          data-target={`#collapse${this.props.dataJSON.id}`}
        >
          {this.props.dataJSON.is_complete == null ||
          this.props.dataJSON.is_complete == "FALSE" ? (
            <i className="fa fa-star color-yellow1-dark mr-2" />
          ) : (
            <i className="fa fa-check color-green1-dark mr-2" />
          )}

          {this.limitChar(this.props.dataJSON.name, 30)}
          <i className="fa fa-chevron-down font-10 accordion-icon" />
        </button>
        <div
          id={`collapse${this.props.dataJSON.id}`}
          className="collapse"
          data-parent="#accordion-1"
        >
          <div className="pt-1 pb-2 pl-3 pr-3">
            {this.props.dataJSON.description}
          </div>
          <div
            onClick={() => this.startMission()}
            className="float-right rounded-xl btn btn-xxs mb-3 rounded-s  shadow-s border-red2-dark  bg-red2-light font-300 mb-n2 mt-2 mr-4"
          >
            {this.props.dataJSON.is_complete == null ||
            this.props.dataJSON.is_complete == "FALSE"
              ? "Start Mission"
              : "Check Missions "}
            <i className="fa fa-arrow-right" />
          </div>
          <div className="clearfix" />
          <hr />
        </div>
      </div>
    );
  }
}

export default withRouter(ListStage);
