import React, { Component } from "react";

import { getUser, getImage } from "../utils/Common";
import BadgeFoto from "../component/BadgePicture";
import Axios from "axios";
import ListHistory from "../component/ListHistory";
import ListHistoryReading from "../component/ListHistoryReading";
import { getHistoryMerge } from "../utils/Repository";
class History extends Component {
  constructor(props) {
    super(props);
    this.state = { dataHistory: [], dataHistoryReading: [] };
  }

  fetchData = () => {
    return getHistoryMerge()
      .then((resp) => resp.data)
      .then((data) => data.result.content)
      .then((dataContent) => this.setState({ dataHistory: dataContent }));
  };
  componentDidMount() {
    this.fetchData();
  }
  
  render() {
    const user = getUser();
    const image = getImage();
    const listVideo = this.state.dataHistory.map((data, index) => (
      <ListHistory dataHistory={data} key={index} />
    ));
    return (
      <div>
        <div
          className="card header-card shape-rounded"
          data-card-height={150}
          style={{ width: "100%" }}
        >
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
        </div>
        <div className="card card-style bg-theme pb-0">
          <div className="content">{listVideo}</div>
        </div>
      </div>
    );
  }
}

export default History;
