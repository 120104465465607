import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactTextFormat from "react-text-format";
class Checklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.props.dataJSON.answer.toLowerCase(),
    };
  }

  render() {
    return (
      <div>
        <div>
          <label>
            {this.props.no + "."}
            <ReactTextFormat linkTarget={"_blank"}>
              {this.props.props.dataJSON.name}
            </ReactTextFormat>
          </label>
        </div>
        <ReactTextFormat linkTarget={"_blank"}>
          {this.props.props.dataJSON.note}
        </ReactTextFormat>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={`checklist`}
            defaultChecked={
              this.props.props.dataJSON.answer.toLowerCase() == "true" ||
              this.props.props.dataJSON.answer.toLowerCase() == "1"
                ? true
                : false
            }
            onChange={(e) => {
              this.props.props.onChange({
                id: this.props.props.dataJSON.id,
                answer: e.target.checked,
              });
            }}
          />
          <label className="custom-control-label" htmlFor={`checklist`}>
            Check me when your task is done
          </label>
        </div>
      </div>
    );
  }
}

export default withRouter(Checklist);
